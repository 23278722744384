import React from 'react';
import { connect } from 'react-redux';
import * as microsoftTeams from "@microsoft/teams-js";
import { useLocation, useParams } from "react-router-dom";
import { apis } from '../../services/Api';
import styles from "./coedetails.module.scss";
import { Row, Col, Button, Card, Form, Container } from "react-bootstrap";
import like from "../../assets/images/thumb.svg";
import liked from "../../assets/images/thumb-fill.svg";
import convertToUnEscape from '../../common/ConvertToUnEscape/ConvertToUnEscape';
import IdeaCardLob from '../../common/Lob/Lob';
import IdeaCardTag from '../../common/Tag/Tag';
import timeDifference from '../../common/Time/Time';
import Artifact from './Artifact/Artifact';
import NoArtifact from './NoArtifact/NoArtifact';
import LikeOrLikes from '../../common/LikeOrLikes/LikeOrLikes';
import CoeDetailsFavorite from './CoeDetailsFavorite/CoeDetailsFavorite';
import Comments from './Comments/Comments';
import { IsAdmin } from '../../services/Auth';
import SearchModal from '../Modal/Modal';
import {Modal} from "bootstrap";
import axios from 'axios';

const CoeDetails = (props) => {

  // let [iid] = React.useState(props.history.location.state.iid);
  //for retrieve params
  let params = useParams();
  let [cid, setCid] = React.useState('allcoe');
  let [ideaData, setIdeaData] = React.useState();
  let [isLiked, setIsLiked] = React.useState();
  let [likeCount, setLikeCount] = React.useState();
  let [userTeamRole] = React.useState(IsAdmin());
  let location = useLocation();
  const [showModel, setShowModel] = React.useState( !!location?.state?.selectedFile ); // To check if the file is selected or not
  const [modelData, setModelData] = React.useState(location?.state?.selectedFile); // Modal data to show in modal
  const modalRef = React.useRef(null); // To handle modal's open/close events

  // Function to get modal by element after modal's mounting
  function getModal(element){
    if(element){
      modalRef.current = new Modal( element );
    }
  }
  
  // Update the model's data container state
  const updateModelData = (data, content) =>{
    setModelData(data);
    modalRef.current.show();
  }

  // Update data if new file is selected (route changes)
  React.useEffect(() => {
    const cb = location?.state?.selectedFile && function(IdeaData) {
      location?.state?.selectedFile?.cq_res?.filename && axios.get( `${process.env.REACT_APP_API_ENDPOINT}/idea/cq_file_content/` + location?.state?.selectedFile?.cq_res?.filename ).then(async (response) => {
        
        location.state.selectedFile.url = IdeaData?.idea_artifact?.find( artifact => artifact.cq_name == location?.state?.selectedFile?.cq_res?.filename )?.url
        setShowModel(!!location?.state?.selectedFile)
        location.state.selectedFile.modalContent = response.data.data
        // modelData.modalContent = response.data.data
        setModelData({...location?.state?.selectedFile})
        modalRef?.current?.show()
        // props.updateModelData( props.data )
      }).catch( err => console.log("errr1\n", err) )
    };
    
    callIdeaApi(cb);
  }, [location]);
  

  // This method call api for det idea data
  const callIdeaApi = async (cb) => {
    let { data: ideaData } = await apis.getIdea(cid, params.iid);
    console.log(ideaData);
    // let tagArray = ideaData.tags.split(",");
    // console.log(tagArray);
    setIdeaData({
      ...ideaData
      // tags: tagArray
    });
    setIsLiked(ideaData.is_liked);
    setLikeCount(ideaData.like_count);
    setCid(ideaData.cid);
    cb && cb(ideaData)
  }


  const likeIdea = async () => {
    console.log("like idea");
    let result = await apis.like(cid, params.iid, 1);
    setIsLiked(1);
    setLikeCount(result.count);
  }

  const unLikeIdea = async () => {
    console.log("unlike idea");
    let result = await apis.like(cid, params.iid, 0);
    setIsLiked(0);
    setLikeCount(result.count);
  }

  const goToEditIdea = () => {
    console.log("goToEditIdea")
    props.history.push({
      pathname: `/createip`,
      state: {
        edit: true,
        iid: params.iid,
        cid
      }
    });
  }
  return (
    <>
      {ideaData &&
        <div className="container-fluid py-5 px-4">
          <SearchModal 
            modalRef={modalRef} 
            getModal={getModal} 
            title={ convertToUnEscape( modelData?.cq_name || modelData?.cq_res?.filename) } 
            redirectUrl={ convertToUnEscape(modelData?.url || modelData?.cq_res?.doc_http_path) } 
            content={ modelData?.modalContent } 
            itemToSearch={location?.state?.searchedWord || "" }
            autoTags={modelData?.cq_res?.auto_tags || []}
          />
          <Row>
            <Col md={6} lg={8}>
              <Row>
                <Col md={8} className={`d-flex ${styles.card_title}`}>
                  <span data-test="CoeDetails-title">
                    {convertToUnEscape(ideaData.title)}
                  </span>
                </Col>
                <Col md={4} className="d-flex justify-content-end">
                  <CoeDetailsFavorite data={{
                    isFavorite: ideaData.is_favorite,
                    cid: params.cid,
                    iid: ideaData.id
                  }} />
                  {userTeamRole == 0 &&
                    <span>
                      <Button variant="link" className="pt-2 ps-4" onClick={() => goToEditIdea()}>
                        {" "}
                        <i className="bi bi-pencil"></i>
                      </Button>
                    </span>
                  }
                </Col>
                <Col>
                  <IdeaCardLob lobs={ideaData.lobs} />
                </Col>
                <Col md={12}>
                  <div className="description d-flex mt-2">
                    <span>
                      {convertToUnEscape(ideaData.description)}
                    </span>
                  </div>
                </Col>
                <Row className="pt-4">
                  <Col md={12} lg={8}>
                    <IdeaCardTag data={ideaData.tags} />
                  </Col>

                  <Col md={12} lg={4} className="pt-3 profile-info">
                    <Col md={12} className="d-flex justify-content-end">
                      <div className="text-center">
                        {" "}
                        <img
                          src={ideaData.display_image}
                          width="40"
                          className="rounded-circle"
                        />{" "}
                      </div>
                      <div className="text-start ps-3 col-md-6">
                        <h5 className="mt-2 mb-0 d-flex">
                          <span className="text-truncate">
                            {convertToUnEscape(ideaData.display_name)}
                          </span>
                        </h5>{" "}
                        <span className="subtitle">{ideaData.category_title}</span>
                        <Col md="auto" className="d-flex">
                          <div className="tag-gray">{timeDifference(ideaData.updated_at)}</div>
                        </Col>
                      </div>
                    </Col>
                  </Col>
                </Row>
                <div className="divider-hr-dark mt-3"></div>
                <Col md="auto" ps-0>
                  {isLiked == 1 ?
                    <Button
                      data-test="CoeDetails-unLikeIdea"
                      onClick={unLikeIdea}
                      variant="link"
                      className="ps-0 d-flex align-items-center"
                    >
                      <img alt="Like" src={liked} className={`${styles.like_img}`} />
                      <span className="label p-2 pt-2 ">
                        <b className="pe-1">{likeCount}</b>{LikeOrLikes(likeCount)}
                      </span>
                    </Button>
                    :
                    <Button
                      data-test="CoeDetails-likeIdea"
                      onClick={likeIdea}
                      variant="link"
                      className="ps-0 d-flex align-items-center"
                    >
                      <img alt="Like" src={like} className={`${styles.like_img}`} />
                      <span className="label p-2 pt-2 ">
                        <b className="pe-1">{likeCount}</b>{LikeOrLikes(likeCount)}
                      </span>
                    </Button>
                  }
                </Col>
              </Row>
              <Row>
                <Col
                  md={12}
                  className="mt-3 info-section d-flex justify-content-between"
                >
                  <h5>Artifact(s)</h5>
                  {/* <Button variant="primary" className="ripple btn-bordered-c">
                    <i className="bi bi-upload pe-2"></i>Artifacts
                  </Button> */}
                </Col>
                {ideaData?.idea_artifact?.length > 0 ?
                  ideaData?.idea_artifact?.map((e, i) =>
                    <Artifact modalRef={modalRef} updateModelData={updateModelData} data={e} searchKeyword={location.state?.searchedWord} key={i} />
                  )
                  :
                  <NoArtifact />
                }
              </Row>
            </Col>
            {/* comments
            key pass for Re-fresh state when user go to post details to another
             */}
            <Comments key={location.pathname} />
          </Row>
        </div>
      }
    </>
  );
};

// const mapStateToProps = state => {
//   return {
//     searchText: state.searchText
//   }
// }


// export default connect(mapStateToProps)(CoeDetails);

export default CoeDetails;
