import React from 'react'
import styles from "./searchdetails.module.scss";
import { useLocation, useHistory } from 'react-router-dom';
import { apis } from '../../services/Api';
import convertToEscape from '../../common/ConvertToEscape/ConvertToEscape';
import convertToUnEscape from '../../common/ConvertToUnEscape/ConvertToUnEscape';

export default function SearchDetails() {
  const location = useLocation()
  const history = useHistory()

  // const { search } = location
  const searchParams = new URLSearchParams(location.search)
  const section = searchParams.get('section')
  const searchedWord = searchParams.get('search')
  const [searchData, setSearchData] = React.useState(undefined)
  const searchIdea = location.state?.searchData || searchData

  // const [section, setSection] = React.useState(searchParams.get('section'))
  // const [searchedWord, setSearchedWord] = React.useState(searchParams.get('search'))

  // console.log(section , searchedWord )

  React.useEffect(() => {
    if (!location.state?.searchData && searchedWord) {
      // call api with word if not getting data from parant ( works while directly accessing with url )
      (async function (text) {
        let result = await apis.searchIdea(text);
        console.log(result);
        result.data.file = result.data?.file?.filter( File => !File.cq_res.filename.endsWith('.docx') )
        setSearchData(result.data);
      } (searchedWord) );
    }
    else if ( !searchedWord ) {
      // Show No data found
    }
    else {
      // section = searchParams.get('section')
    }
  }, [location])

  const changeTab = (event) =>{
    const section = event.currentTarget.getAttribute("data-section")
    // setSection(section)
    
    const queryParams = new URLSearchParams(searchedWord ? { section, search: searchedWord } : { section } )
    history.replace({
      pathname: '/search-details',
      state : {
        searchData: searchIdea
      },
      search: queryParams.toString()
    })
  }

  const goToIdea = (event) => {
    const id = event.currentTarget.getAttribute("data-id")
    const index = event.currentTarget.getAttribute("data-index")
    history.push({
      pathname: `/allcoe/idea/${id}`,
      state: {
        id,
        searchedWord,
        // selectedFile: searchIdea?.file[index]
      }
    });
  }

  const formatResult = (item) => {
    const exp = new RegExp(`\\w*(${searchedWord})\\w*`, 'gi');
    // text.match(/\w*(ain)\w*/g)
    const yelloText = (word) => `<span class='${styles.yellow_bg}'>${word}</span>`;
    const unEscape = (text) => convertToUnEscape(text).replaceAll( exp, yelloText);
    return (
      <span dangerouslySetInnerHTML={{__html: unEscape(item) }} />
    )
  }

  const titleLength = searchIdea?.title?.length
  const tagLength = searchIdea?.tag?.length
  const descriptionLength = searchIdea?.description?.length
  const fileLength = searchIdea?.file?.length || 0


  return (<div className="container mt-3">
    {/* <!-- Nav tabs --> */}
    <ul className={`nav  nav-tabs ${styles['nav-pills']}  ${styles.navbb} `} id="pills-tab" role="tablist">
      <span className={styles.searchtxt}>
        Search Result
      </span>
      <li onClick={changeTab} data-section='all' class="nav-item" role="presentation">
        <button className={`${styles['nav-link']} ${(!section || section==='all') ? (styles['active'] + ' active') : ''} ${styles.navlink} nav-link `} id="all-tab" data-bs-toggle="pill" data-bs-target="#all" type="button" role="tab" aria-controls="all" aria-selected="true">All</button>
      </li>
      <li onClick={changeTab} data-section='title' class="nav-item" role="presentation">
        <button className={`${styles['nav-link']} ${(section==='title') ? styles['active'] : ''} ${styles.navlink} nav-link `} id="iptitle-tab" data-bs-toggle="pill" data-bs-target="#iptitle" type="button" role="tab" aria-controls="iptitle" aria-selected="false">IP Title</button>
      </li>
      <li onClick={changeTab} data-section='description' class="nav-item" role="presentation">
        <button className={`${styles['nav-link']} ${(section==='description') ? styles['active'] : ''} ${styles.navlink} nav-link`} id="desc-tab" data-bs-toggle="pill" data-bs-target="#desc" type="button" role="tab" aria-controls="desc" aria-selected="false">Description</button>
      </li>
      <li onClick={changeTab} data-section='tag' class="nav-item" role="presentation">
        <button className={`${styles['nav-link']} ${(section==='tag') ? styles['active'] : ''} ${styles.navlink} nav-link `} id="tags-tab" data-bs-toggle="pill" data-bs-target="#tags" type="button" role="tab" aria-controls="tags" aria-selected="false">Tags</button>
      </li>
      {/* <li onClick={changeTab} data-section='file' className={"nav-item"} role="presentation">
        <button className={`${styles['nav-link']} ${(section==='file') ? styles['active'] : ''} ${styles.navlink} nav-link `} id="files-tab" data-bs-toggle="pill" data-bs-target="#files" type="button" role="tab" aria-controls="filess" aria-selected="false">Files</button>
      </li> */}
    </ul>


    {/* <!-- Tab panes --> */}

    <div className="tab-content d-flex justify-content-center divhw">
      <div id="all" className={`container tab-pane ${section === 'all' ? 'active' : 'fade'}`}><br />
        <span className={`${styles['headtitle']}`}>All</span>
        { !searchedWord || searchedWord==='null' ? 
          <h4>Search someting to get results</h4> 
          :
          (titleLength || tagLength || descriptionLength || fileLength) 
          ? 
          <> 
          
          {
            searchIdea?.title?.map(Title => (
              <div className={`${styles['bgw']}`}>
                <span onClick={goToIdea} data-id={Title.id} className={`${styles['spandesc']}`}>
                  {formatResult(Title.name)}
                </span>
                {/* <span className={`${styles['edtch']}`}>EdTech</span> */}
              </div>
            ))
          }

          {
            searchIdea?.description?.map(Title => (
              <div className={`${styles['bgw']}`}>
                <span onClick={goToIdea} data-id={Title.id} className={`${styles['spandesc']}`}>
                  {formatResult(Title.name)}
                </span>
                {/* <span className={`${styles['edtch']}`}>EdTech</span> */}
              </div>
            ))
          }

          {
            searchIdea?.tag?.map(Title => (
              <div className={`${styles['bgw']}`}>
                <span onClick={goToIdea} data-id={Title.id} className={`${styles['spandesc']}`}>
                  {formatResult(Title.name)}
                </span>
                {/* <span className={`${styles['edtch']}`}>EdTech</span> */}
              </div>
            ))
          }

          {/* {
            searchIdea?.file?.map( (Title,ind) => (
              <div className={`${styles['bgw']}`}>
                <span onClick={goToIdea} data-id={Title.uuid} data-index={ind} className={`${styles['spandesc']}`}>
                  {formatResult(Title.title)}
                </span>
              </div>
            ))
          } */}
          </>
        : <h4> No Data Found </h4>
        }
      </div>
      <div id="iptitle" className={`container tab-pane ${section === 'title' ? 'active' : 'fade'} `}><br />
        <span className={`${styles['headtitle']}`}>IP Title</span>
        { !searchedWord ? 
          <h4>Search someting to get results</h4> 
          :
          (titleLength) 
          ? 
          searchIdea?.title?.map(Title => (
            <div className={`${styles['bgw']}`}>
              <span onClick={goToIdea} data-id={Title.id} className={`${styles['spandesc']}`}>
                {formatResult(Title.name)}
              </span>
              {/* <span className={`${styles['edtch']}`}>EdTech</span> */}
            </div>
          ))
          : <h4> No Data Found </h4>
        }

      </div>
      <div id="desc" className={`container tab-pane ${section === 'description' ? 'active' : 'fade'}`}><br />
        <span className={`${styles['headtitle']}`}>Description</span>
        { !searchedWord ? 
          <h4>Search someting to get results</h4> 
          :
          (descriptionLength) 
          ? 
          searchIdea?.description?.map(Title => (
            <div className={`${styles['bgw']}`}>
              <span onClick={goToIdea} data-id={Title.id} className={`${styles['spandesc']}`}>
                {formatResult(Title.name)}
              </span>
              {/* <span className={`${styles['edtch']}`}>EdTech</span> */}
            </div>
          ))
          : <h4> No Data Found </h4>
        }
      </div>
      <div id="tags" className={`container tab-pane ${section === 'tag' ? 'active' : 'fade'}`}><br />
        <span className={`${styles['headtitle']}`}>Tags</span>
        { !searchedWord ? 
          <h4>Search someting to get results</h4> 
          :
          (tagLength) 
          ? 
          searchIdea?.tag?.map(Title => (
            <div className={`${styles['bgw']}`}>
              <span onClick={goToIdea} data-id={Title.id} className={`${styles['spandesc']}`}>
                {formatResult(Title.name)}
              </span>
              {/* <span className={`${styles['edtch']}`}>EdTech</span> */}
            </div>
          ))
          : <h4> No Data Found </h4>
        }
      </div>
      {/* <div id="files" className={`container tab-pane ${section === 'file' ? 'active' : 'fade'}`}><br />
        <span className={`${styles['headtitle']}`}>Files</span>
        { !searchedWord ? 
          <h4>Search someting to get results</h4> 
          :
          (fileLength) 
          ? 
          searchIdea?.file?.map( (Title,ind) => (
            <div className={`${styles['bgw']}`}>
              <span onClick={goToIdea} data-id={Title.uuid} data-index={ind} className={`${styles['spandesc']}`}>
                {formatResult(Title.title)}
              </span>
            </div>
          ))
          : <h4> No Data Found </h4>
        }
      </div> */}
    </div>
  </div>

  )

}
