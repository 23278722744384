import axios from 'axios';
import { getTokenDetails, isLogin } from './Auth';

export function jwtInterceptor() {
    axios.interceptors.request.use(request => {
        // add auth header with jwt if account is logged in and request is to the api url
        const account = getTokenDetails();
        const isLoggedIn = isLogin();
        const isApiUrl = `${process.env.REACT_APP_API_ENDPOINT}`;

        if (isLoggedIn && isApiUrl) {
            request.headers.common.Authorization = `Bearer ${account.accessToken}`;
        }

        return request;
    });
}