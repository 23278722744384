import React from 'react';
import styles from "./popoverview.module.scss";
import { Popover, Button, OverlayTrigger, Row, Col, Form } from "react-bootstrap";

const PopoverView = (props) => {
  let [listAndGrid] = React.useState([{
    name: 'Grid',
    className: 'bi bi-grid-3x3-gap-fill'
  }, {
    name: 'List',
    className: 'bi bi-list-ul'
  }]);
  let [selected, setSelected] = React.useState(listAndGrid[0]);
  let [show, setShow] = React.useState(false);

  let changeStyle = (n) => {
    setShow(!show);
    if (n) setSelected(listAndGrid[1]);
    else setSelected(listAndGrid[0]);
    props.changeStyle(n);
  }

  return (
    <>
      {['bottom'].map((placement) => (
        <OverlayTrigger
          // trigger="click"
          show={show}
          key={placement}
          placement={placement}
          overlay={
            <Popover id={`popover-positioned-${placement}`}>

              <Popover.Body className="c-popover">
                <Row>
                  <Col>
                    <Button variant="link" onClick={() => changeStyle(1)} data-test="PopoverView-change-style-list">
                      <i className="bi bi-list-ul"></i>
                      <div className="lable">List</div>
                    </Button>


                  </Col>
                  <Col>
                    <Button variant="link" onClick={() => changeStyle(0)} data-test="PopoverView-change-style-grid">
                      <i className="bi bi-grid-3x3-gap-fill"></i>
                      <div className="label ">Grid</div>
                    </Button>


                  </Col>
                </Row>
              </Popover.Body>
            </Popover>
          }
        >
          <Button variant="link" className="popover-view" onClick={() => setShow(!show)}><i className={selected.className}></i>
            <Form.Label>{selected.name} <i className="bi bi-caret-down-fill arrow-dw"></i></Form.Label>
          </Button>

        </OverlayTrigger>
      ))}
    </>
  );
};

export default PopoverView;
