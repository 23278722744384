import React, { useRef } from 'react';
import { NotificationContainer } from 'react-notifications';
import styles from "../coedetails.module.scss";
import { Row, Col, Card, Form, Modal, Button } from "react-bootstrap";
import nochats from "../../../assets/images/no-chats.svg";
import comment from "../../../assets/images/comment.svg";
import sendmsg from "../../../assets/images/send.svg";
import { useHistory, useParams } from "react-router-dom";
import { apis } from '../../../services/Api';
import convertToUnEscape from '../../../common/ConvertToUnEscape/ConvertToUnEscape';
import convertToEscape from '../../../common/ConvertToEscape/ConvertToEscape';
import timeDifference from '../../../common/Time/Time';
import Like from './Like/Like';
import editImg from "../../../assets/images/edit2.png";
import { connect } from 'react-redux';



function Comments(props) {
    let history = useHistory();
    //for retrieve params
    const params = useParams();
    let [commentList, setCommentList] = React.useState([]);
    let [clientCommentCount, setClientCommentCount] = React.useState(0);
    let [isClientFeedback, setClientFeedback] = React.useState(false);
    let [commentText, setCommentText] = React.useState("");
    const htmlElRef = useRef(null);

    const [show, setShow] = React.useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    let [editModelData, setEditModelData] = React.useState();

    React.useEffect(() => {
        apiCall();
    }, [])

    const apiCall = async () => {
        let { data } = await apis.getComments(params.cid, params.iid);
        setCommentList(data);
        data.forEach(element => {
            if (element.is_client_feedback)
                setClientCommentCount(oldData => oldData + 1);
        });

        var div = document.getElementById('scrollBottom');
        div.scrollTop = div.scrollHeight - div.clientHeight;
    }

    const handleClientFeedback = () => {
        setClientFeedback(!isClientFeedback);
    }

    const handleEditClientFeedback = () => {
        setEditModelData({ ...editModelData, "is_client_feedback": !editModelData.is_client_feedback });
    }

    const handleCommentTextChange = (e) => {
        setCommentText(convertToEscape(e.target.value));
    }

    const handleEditCommentTextChange = (e) => {
        console.log(e.target.value);
        setEditModelData({ ...editModelData, "comment_txt": convertToEscape(e.target.value) });
    }

    const sendComment = async (e) => {
        if (!commentText) {
            // NotificationManager.warning('Comment input filed should not be empty.');
            htmlElRef.current.focus();
            console.log(htmlElRef)
            return;
        }
        e.preventDefault();
        console.log(commentText, isClientFeedback);
        setCommentText("");
        await apis.addComment(params.cid, params.iid, commentText, isClientFeedback);
        apiCall();
    }

    const editComment = async (e) => {
        await apis.editComments(params.cid, params.iid, editModelData);
        apiCall();
    }


    return (
        <React.Fragment>
            <Col md={6} lg={4} className={`chats ${styles.chat_wrapper}`}>
                <div className={`divider-btm ${styles.chat_info}`}>
                    <Col md="auto">
                        <div className="d-flex p-3">
                            <img
                                alt="Comments"
                                src={comment}
                                className={`${styles.comment_img}`}
                            />
                            <span className="label p-2">
                                <b className="pe-1">{commentList.length}</b>Comments (<b>{clientCommentCount}</b> Client
                                feedback)
                            </span>
                        </div>
                    </Col>
                </div>
                <div
                    className={`mb-4 overflow-auto overflow-x-hidden ${styles.chat_messages}`}
                    id="scrollBottom"
                >

                    {commentList.length > 0 ?
                        commentList.map((e, i) =>
                            <Col md={12} className="mb-3" key={i}>
                                <Card className={`${styles.chat_card}`}>
                                    {e.is_client_feedback && <div className="tag-feedback">Client Feedback</div>}
                                    <Card.Body>
                                        <div className="d-flex flex-row mt-2 align-items-center ">
                                            <div className="image mr-3">
                                                <img
                                                    src={e.display_image}
                                                    className="rounded-circle"
                                                    width="30"
                                                />
                                            </div>
                                            <Col className="d-flex mb-1 ps-2">
                                                <span
                                                    className={`w-75 text-truncate ${styles.card_title}`}
                                                >
                                                    {e.display_name}
                                                </span>
                                            </Col>

                                            <Col className="d-flex mb-1 ps-2 justify-content-end">
                                                {props?.userId == e.user_id && <span>
                                                    <img alt="Like" src={editImg} className="cursor-pointer" onClick={() => { setEditModelData(e); handleShow() }} />
                                                </span>
                                                }
                                                <span className="ps-2 pe-3">{timeDifference(e.updated_at)}</span>
                                                <Like data={{ like_count: e.like_count, is_liked: e.is_liked, cmd_id: e.id }} />
                                            </Col>

                                        </div>
                                        <Col>
                                            <div className="description d-flex mt-2">
                                                {convertToUnEscape(e.comment_txt)}
                                            </div>
                                        </Col>
                                    </Card.Body>
                                </Card>
                            </Col>
                        )
                        :
                        <Row>
                            <Col
                                md={12}
                                className="d-flex align-items-center justify-content-cenetr flex-column"
                            >
                                <img
                                    alt="No Chats"
                                    src={nochats}
                                    className={`${styles.no_content}`}
                                />
                                <h5 className="mt-3">No Chats yet!</h5>
                                <small>Your message will appear here.</small>
                            </Col>
                        </Row>
                    }

                </div>
                <Col md={12} className={`${styles.chat_bottom_bar}`}>
                    <input
                        className="form-control form-control-lg"
                        type="text"
                        placeholder="Type a new message"
                        aria-label="message"
                        onChange={handleCommentTextChange}
                        value={convertToUnEscape(commentText)}
                        ref={htmlElRef}
                        data-test="Comments-text"
                    />
                    <Col
                        md={12}
                        className="w-100 d-flex align-items-center justify-content-between py-3"
                    >

                        <Col md="auto" className={`${styles.chk_label}`}>
                            <Form.Check
                                inline
                                label="Client feedback included in comment"
                                name="group1"
                                data-test="Comments-client-feedback"
                                checked={isClientFeedback}
                                onChange={handleClientFeedback}
                            />
                        </Col>

                        <Col md="auto">
                            <a onClick={sendComment} data-test="Comments-send" className='cursor-pointer'>
                                <img
                                    alt="send"
                                    src={sendmsg}
                                    className={`${styles.img_send}`}
                                />
                            </a>
                        </Col>
                    </Col>
                </Col>
            </Col>
            <NotificationContainer />
            <Modal show={show} onHide={handleClose} >
                <Modal.Header closeButton className={`${styles.modal_backdrop}`}>
                    <Modal.Title>Edit Comment</Modal.Title>
                </Modal.Header>
                <Modal.Body className={`${styles.modal_backdrop}`}>
                    <Col md={12} className={`${styles.chat_bottom_bar}`}>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                            <Form.Control as="textarea" rows={3} value={convertToUnEscape(editModelData?.comment_txt)} onChange={handleEditCommentTextChange} />
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea2">
                            <Col
                                md={12}
                                className="w-100 d-flex align-items-center justify-content-between py-3"
                            >
                                <Form.Check
                                    inline
                                    label="Client feedback included in comment"
                                    name="group1"
                                    data-test="Comments-client-feedback"
                                    checked={editModelData?.is_client_feedback}
                                    onChange={handleEditClientFeedback}
                                />
                            </Col>
                        </Form.Group>

                    </Col>
                </Modal.Body>
                <Modal.Footer className={`${styles.chat_bottom_bar}`}>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={() => { editComment(); handleClose() }}>
                        Save Changes
                    </Button>
                </Modal.Footer>
            </Modal>
        </React.Fragment>
    );
}

const mapStateToProps = state => {
    return {
        userId: state.userId
    }
}

export default connect(mapStateToProps)(Comments);