import React from 'react';
import { Col, Button, Card } from "react-bootstrap";
import styles from "../coedetails.module.scss";
import noartifacts from "../../../assets/images/no-artifacts.svg";


function NoArtifact(props) {

    return (
        <React.Fragment>
            <Col md={12} className="mt-3 d-flex align-items-center justify-content-cenetr flex-column" >
                <img
                    data-test="NoArtifact-image"
                    alt="No Artifact"
                    src={noartifacts}
                    className={`${styles.no_content}`}
                />
                <h5 className="mt-3">No Artifacts!</h5>
                {/* <small>Your message will appear here.</small> */}
            </Col>
        </React.Fragment>
    );
}

export default NoArtifact;