import 'bootstrap-icons/font/bootstrap-icons.css';
import { BrowserRouter, Switch, Route } from "react-router-dom";
import { PrivateRoute } from './component/PrivateRoute/PrivateRoute';
import SignInStart from './component/SignIn/SignInStart';
import SignInEnd from './component/SignIn/SignInEnd';
import SignIn from './component/SignIn/SignIn';
import HeaderNav from './component/Header/Header';
import Home from './component/Home/Home';
import AllCoE from './component/AllCoe/AllCoe';

import CoeDetails from './component/CoEDetails/CoeDetails';
import CreateIP from './component/CreateIP/CreateIP';
import SearchDetails from './component/SearchDetails/SearchDetails';

import './App.scss';
import 'react-notifications/lib/notifications.css';


function App() {
  return (
    <BrowserRouter>
      <div className="App">
        <HeaderNav />
        <Switch>
          <Route path="/sign-in/sign-in-start" component={SignInStart} />
          <Route path="/sign-in/sign-in-end" component={SignInEnd} />
          <Route path="/sign-in" component={SignIn} />
          <PrivateRoute path="/:cid/idea/:iid" component={CoeDetails} />
          <PrivateRoute path="/search-details" component={SearchDetails} />
          <PrivateRoute path="/createip" component={CreateIP} />
          <Route path="/:cid/ideas" render={(props) => (
            <PrivateRoute component={AllCoE} path="/:cid/ideas" key={props.match.params.cid} {...props} />)
          } />
          <PrivateRoute exact path="/" component={Home} />
        </Switch>
      </div>
    </BrowserRouter>
  );
}

export default App;
