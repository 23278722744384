import React from 'react';
import { Row, Col, Button, } from "react-bootstrap";
import { useParams } from 'react-router-dom';
import InfiniteScroll from "react-infinite-scroll-component";
import { connect } from 'react-redux';
import Filter from "../Filter/Filter";
import AllCoEGrid from "../AllCoEGrid/AllCoeGrid";
import AllCoEList from "../AllCoEList/AllCoEList";
import PopoverView from "../PopoverView/PopoverView";
import { apis } from '../../services/Api';
import { IsAdmin } from '../../services/Auth';


const AllCoE = (props) => {
  //for retrieve params
  const params = useParams();
  const [selectedStyle, setSelectedStyle] = React.useState(0);
  let [ideaList, setIdeaList] = React.useState([]);
  let [hasMore, setHasMore] = React.useState(true);
  let [pageNo, setPageNo] = React.useState(1);
  let [limit] = React.useState(6);
  let [userTeamRole, setUserTeamRole] = React.useState(IsAdmin());
  //filter state
  let [domain, setDomain] = React.useState([]);
  let [sortBy, setSortBy] = React.useState();
  let [tags, setTags] = React.useState([]);
  //CoeID for coe drop-down
  let [coeID, setCoeID] = React.useState(params.cid);
  //for filter submit api call
  let [filterSubmitTime, setFilterSubmitTime] = React.useState(Date.now());
  let [resatCount, setResatCount] = React.useState(0);

  React.useEffect(() => {
    console.log(params);
    if (!hasMore) return;
    apiCall();
  }, [pageNo, coeID, filterSubmitTime]);



  const apiCall = async () => {
    let response = await apis.getIdeas(coeID, pageNo, limit, null, tags, domain, sortBy);
    if (response.length < limit) {
      setHasMore(false);
    }
    if (response.length > 0) setIdeaList((oldArray) => [...oldArray, ...response]);
  }

  const goToIdeaDetails = (iid) => {
    props.history.push({
      pathname: `/${params.cid
        }/idea/${iid}`
    });
  }

  const convertToUnEscape = (txt) => {
    return unescape(txt);
  }

  const changeStyle = (n) => {
    setSelectedStyle(n);
  }

  const handleSubmit = async ({ submittedTags, submittedDomain, submittedSortBy }) => {
    console.log("handleSubmit");
    setIdeaList([...[]]);
    setTags([...submittedTags]);
    setDomain([...submittedDomain]);
    setSortBy(submittedSortBy);
    setHasMore(true);
    setPageNo(1);
    setFilterSubmitTime(Date.now())
  }

  const handleResat = async () => {
    console.log("handleSubmit");
    setIdeaList([...[]]);
    setTags([...[]]);
    setDomain([...[]]);
    setSortBy(null);
    setHasMore(true);
    setPageNo(1);
    setFilterSubmitTime(Date.now());
    setResatCount((old) => old + 1);
  }

  const handleCoeChnage = async (e) => {
    setHasMore(true);
    setPageNo(1);
    console.log(e.target.value);
    //resate filters
    setIdeaList([...[]]);
    setCoeID(e.target.value);
    props.history.push({
      pathname: `/${e.target.value}/ideas`
    });
  }

  const goToAddNewIdea = () => {
    props.history.push({
      pathname: `/createip`,
      state: {
        edit: false
      }
    });
  }

  return (
    <>
      <div className="container-fluid py-5 px-4">
        <Row className="mb-3">
          <Col md={2} lg={2} className="dropdown-no-border">
            <select className="form-select" onChange={(e) => handleCoeChnage(e)} id="dropdown-all-coe">
              <option active value={props.allCoe.id} selected={props.allCoe.id === coeID} >{props.allCoe.title}  ({props.allCoe.idea_count})</option>
              {props.coeList.map(e => <option value={e.id} selected={e.id === coeID} >{e.title} ({e.idea_count})</option>)}
            </select>
          </Col>

          <Col lg={10}>
            <div className="d-flex justify-content-end">
              {userTeamRole == 0 &&
                <Col lg="auto">
                  <Button variant="primary" className="ripple me-4" onClick={() => goToAddNewIdea()} id="add-new-coe" >
                    <i className="bi bi-plus "></i> IP
                  </Button>
                </Col>
              }
              <span className="col-auto"> | </span>
              <Col lg="auto" className="filter-collapse pe-0">
                <Filter
                  handleSubmit={handleSubmit}
                  handleResat={handleResat}
                  key={resatCount}
                />
              </Col>
              <span className="col-auto"> | </span>
              <Col lg="auto" className="">
                <PopoverView changeStyle={changeStyle} />
              </Col>
            </div>
          </Col>
        </Row>

        <InfiniteScroll
          dataLength={ideaList.length}
          next={() => setPageNo(prevState => prevState + 1)}
          hasMore={hasMore}
          loader={<h4>Loading...</h4>}
          // className="row"
          // style={{ display: 'flex', flexDirection: 'column-reverse' }} //To put endMessage and loader to the top.
          endMessage={
            <p style={{ textAlign: "center" }}>
              {/* <b>Yay! You have seen it all</b> */}
            </p>
          }
        >

          {
            selectedStyle ?
              <AllCoEList ideaList={ideaList} convertToUnEscape={convertToUnEscape} goToIdeaDetails={goToIdeaDetails} />
              :
              <AllCoEGrid ideaList={ideaList} convertToUnEscape={convertToUnEscape} goToIdeaDetails={goToIdeaDetails} />
          }
        </InfiniteScroll>
      </div>
    </>
  );
};

const mapStateToProps = state => {
  return {
    coeList: state.coeList,
    allCoe: state.defaultCoe
  }
}

export default connect(mapStateToProps)(AllCoE);
