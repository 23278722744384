import React from 'react';
import { connect } from 'react-redux';
import {
  Navbar,
  Container,
  Row,
  Col,
  Dropdown
} from "react-bootstrap";
import { useHistory, useLocation } from "react-router-dom";
import styles from "../Header/header.module.scss";
import logo from "../../assets/images/harbinger-logo.svg";
import Text from "../../assets/images/textgray.svg";
import PPT from "../../assets/images/powerpoint-gray.svg";
import Video from "../../assets/images/video-gray.svg";
import PDF from "../../assets/images/pdf-gray.svg";
import fileicon from "../../assets/images/avatar.svg";
import { ReactSearchAutocomplete } from 'react-search-autocomplete'
import { apis } from '../../services/Api';
import convertToUnEscape from '../../common/ConvertToUnEscape/ConvertToUnEscape';
import convertToEscape from '../../common/ConvertToEscape/ConvertToEscape';
import { addAllCoEsCount, addCoeList, addSearchText,addUserId } from '../../actions/SkillAction';
import { isLogin, logInEvent } from '../../services/Auth';
import { Debounce } from '../../services/debounce';

const HeaderNav = (props) => {
  let [searchIdea, setSearchIdea] = React.useState([]);
  let [userPhoto, setUserPhoto] = React.useState('https://hgipportal.blob.core.windows.net/hg-teams-app/avatar.svg');
  let [userLogin, setUserLogin] = React.useState(false);
  const [showSearchDropdown, setShowSearchDropdown ] = React.useState(false)
  const searchInputRef = React.useRef()
  const searchedWord = React.useRef()

  let history = useHistory();
  let location = useLocation();

  React.useEffect(() => {
    var inputNodes = document.querySelector(".clear-icon");
    if (!inputNodes) return;
    //console.log(inputNodes);
    inputNodes.click();
  }, [location]);

  React.useEffect(() => {
    logInEvent.subscribe(message => {
      console.log(message);
      if (message) {
        getUserDetails();
        getCategories();
      }
    })
    if (isLogin()) {
      getUserDetails();
      //for display drop down if user open list page directly.
      //also calculate total ips.
      getCategories(); 
    }
  }, []);

  React.useEffect( ()=>{
    
    document.addEventListener("click", (event) => {
      // console.log(showSearchDropdown, searchInputRef?.current );
      if ( searchInputRef?.current && !searchInputRef.current.contains(event.target)) {
        setShowSearchDropdown(false)
      }
  })
  return () => {
      console.log("unmounted")
      document.removeEventListener("click", null)
  }

  }, [])

  const getUserDetails = async () => {
    let result = await apis.getUserDetails();
    setUserPhoto(result.image);
    setUserLogin(true);
    props.addUserId(result.id);
    // console.log(result);
  }

  const getCategories = async () => {
    let result = await apis.getCategories('all'), allCoeCount = 0;
    // setCoeList(result.data);
    props.addCoeList(result.data);
    result.data.forEach(element => {
      if (!Number(element.idea_count)) return;
      allCoeCount = Number(element.idea_count) + allCoeCount;
    });
    props.addAllCoEsCount(allCoeCount);
  }

  const apiCall = async (text) => {
    let result = await apis.searchIdea(convertToEscape(text));
    console.log(result);
    result.data.file = result?.data?.file?.filter( File => !File?.cq_res?.filename?.endsWith('.docx') ) || []
    setSearchIdea(result.data);
  }


  const handleOnSearch = (event, results) => {
    // onSearch will have as the first callback parameter
    // the string searched and for the second the results.
    searchedWord.current = event.target.value;
    if (event.target.value.length === 0) {
      return setSearchIdea({
        title: [],
        description: [],
        tag: [],
        file: []
    });
    };
    Debounce(
      ()=>apiCall(event.target.value)
    )
  }

  const handleOnHover = (result) => {
    // the item hovered
    console.log(result)
  }

  const handleOnSelect = (event) => {
    event.stopPropagation();
    // the item selected
    const id = event.currentTarget.getAttribute("data-id")
    const section = event.currentTarget.getAttribute("data-section")
    const fileIndex = event.currentTarget.getAttribute("file-index")
    
    
    props.addSearchText(id);
    goToIdeaDetails(id, fileIndex);
  }

  const showAllSearch = (event) => {
    event.stopPropagation()
    const section = event.currentTarget.getAttribute("data-section")
    const queryParams = new URLSearchParams({section, search: searchedWord.current })
    setShowSearchDropdown(false)
    history.push({
      pathname: '/search-details',
      state : {
        searchData: searchIdea
      },
      search: queryParams.toString()
    })
  }

  const goToIdeaDetails = (iid, fileIndex) => {
    setShowSearchDropdown(false)
    history.push({
      pathname: `/allcoe/idea/${iid}`,
      state: {
        iid,
        searchedWord: searchedWord.current,
        selectedFile: fileIndex && searchIdea?.file[fileIndex]
      }
    });
  }

  const handSingOut = () => {
    setUserLogin(false);
    localStorage.removeItem("auth.result");
    localStorage.removeItem("auth.userType");
    history.push({
      pathname: `/sign-in`
    });
  }


  const formatResult = (item) => {
    const exp = new RegExp(`\\w*(${searchedWord.current})\\w*`, 'gi');
    // text.match(/\w*(ain)\w*/g)
    const yelloText = (word) => `<span class='${styles.yellow_bg}'>${word}</span>`;
    const unEscape = (text) => convertToUnEscape(text).replaceAll( exp, yelloText);
    return (
      <span dangerouslySetInnerHTML={{__html: unEscape(item) }} />
    )
  }

  const getFileIcon = (name) => {
    const ext = name?.split('.').pop()?.toLowerCase()
    const text = new Set(['txt', 'doc', 'docx'])
    const video = new Set(['mp4', '3gp', 'mov', 'flv', 'mkv', 'avi'])
    const ppt = new Set([ 'pptm', 'ppt', 'pptx' ])

    if( text.has(ext) ) return Text
    else if( ext === 'pdf' ) return PDF
    else if( ppt.has(ext) ) return PPT
    else if( video.has(ext) ) return Video
      
  }
  
  
  return (
    <>
      <Navbar bg="dark" variant="dark" className="sticky-top">
        <Container fluid>
          <Col md={12}>
            <Row className="align-items-center">
              <Col lg={4} md={12}>
                <Navbar.Brand href="/">
                  <img alt="Harbinger Logo" src={logo} />
                  <span className={`px-4 ${styles.divider_sm}`}> | </span>
                  IP Knowledgebase
                </Navbar.Brand>
              </Col>
              {/* <Col md={12} lg={4} className="d-flex justify-content-end my-3 my-lg-0" md-6 >
                <div className="input-group">
                  <Form.Control
                    type="email"
                    className="searchbox"
                    placeholder="Search by name, CoE, Domain, Tag"
                  />
                  <div className="input-group-append search">
                    <i className="bi bi-search"></i>
                  </div>
                </div>
              </Col> */}
              <Col className="col-sm-12 col-md-12 col d-flex justify-content-end align-items-center my-3 my-lg-0 col-lg-8">
                <Col
                  sm={10}
                  md={9}
                  lg={6}
                  className="d-flex justify-content-end"
                >
                  <div className="input-group" ref={searchInputRef} onClick={()=>setShowSearchDropdown(true)} style={{ visibility: userLogin ? "visible" : "hidden" }}>
                    <div className="w-100">
                      <div className={`${styles.input_icons}`}>
                        <i className={`bi bi-search ${styles.icon}`}></i>
                        <i className={`${styles.icon_border}`}></i>
                        <input onChange={handleOnSearch} className={`${styles.input_field}`} type="text" placeholder="Search by IP Title / Description / Tag"></input>
                        {
                          showSearchDropdown && <div className={`${styles.dropdown_content}`}>

                          { !!searchIdea?.title?.length && <div className={`${styles.ip_parent}`}>
                            <span className={`${styles.ip_child}`}>IP Title</span>
                            { searchIdea?.title?.slice(0,2)?.map( (item, index) =>(
                              <span 
                                data-id={item.id} 
                                data-section='title' 
                                className={`${styles.alink}`} 
                                onClick={handleOnSelect} 
                              >
                                {formatResult(item.name)}
                              </span>
                            ))                            
                            }

                            {!!(searchIdea?.title?.length > 2) &&  <span data-section='title' className={`${styles.alink_more}`} onClick={showAllSearch} >More...</span>}
                            
                          </div>}

                          { !!searchIdea?.description?.length && <div className={`${styles.desc_parent}`}>
                            <span className={`${styles.desc_child}`}>Description</span>
                            { searchIdea?.description?.slice(0,2)?.map( (item, index) =>(
                              <span 
                                data-id={item.id} 
                                data-section='description' 
                                className={`${styles.alink}`} 
                                onClick={handleOnSelect} 
                              >
                                {formatResult(item.name)}
                              </span>
                            ))                            
                            }
                            {!!(searchIdea?.description?.length > 2) &&  <span data-section='description' className={`${styles.alink_more}`} onClick={showAllSearch} >More...</span>}
                          </div>
                          }

                          {
                          !!searchIdea?.tag?.length && <div className={`${styles.tag_parent}`}>
                            <span className={`${styles.tag_child}`}>Tags</span>
                            { searchIdea?.tag?.slice(0,2)?.map( (item, index) =>(
                              <span 
                                data-id={item.id} 
                                data-section='tag' 
                                className={`${styles.alink}`} 
                                onClick={handleOnSelect} 
                              >
                                {formatResult(item.name)}
                              </span>
                            ))                            
                            }
                            {!!(searchIdea?.tag?.length > 2) &&  <span data-section='tag' className={`${styles.alink_more}`} onClick={showAllSearch} >More...</span>}

                          </div>
                          }
                          
                          {/* {
                            !!searchIdea?.file?.length && <div className={`${styles.file_parent}`}>
                            <span className={`${styles.file_child}`}>Files</span>
                            { searchIdea?.file?.slice(0,3)?.map( (item, index) =>(
                              <span 
                                data-id={item.uuid} 
                                file-index={index}
                                data-section='file' 
                                className={`${styles.alink}`} 
                                onClick={handleOnSelect} 
                              >
                                <img src={getFileIcon(item.cq_res?.filename)} /> {formatResult(item.cq_res?.filename)}
                              </span>
                            ))
                            }
                            {!!(searchIdea?.file?.length > 3) &&  <span data-section='file' className={`${styles.alink_more}`} onClick={showAllSearch} >More...</span>}

                          </div>
                          } */}
                          </div>
                        }
                      </div>
                      {/* <ReactSearchAutocomplete
                        items={searchIdea}
                        onSearch={handleOnSearch}
                        onHover={handleOnHover}
                        onSelect={handleOnSelect}
                        onFocus={handleOnFocus}
                        autoFocus
                        formatResult={formatResult}
                        fuseOptions={{ keys: ["name", "description", "tags"] }}
                        placeholder="Search by IP Title / Description / Tag"
                        inputDebounce={0}
                        showIcon={false}
                        styling={
                          {
                            hoverBackgroundColor: "#05558F",
                            placeholderColor: "#000",
                            iconColor: "#000",
                            lineColor: "#FFFFFF",
                            backgroundColor: "#fff",
                            border: "0 none",
                            color: "#000",
                          }
                        }
                      /> */}
                    </div>
                  </div>
                </Col>
                <Col lg="auto" className="d-flex justify-content-end">
                  <Dropdown style={{ visibility: userLogin ? "visible" : "hidden" }}>
                    <Dropdown.Toggle variant="" id="dropdown-basic">
                      <img
                        src={userPhoto}
                        width="40"
                        height="40"
                        className="rounded-circle"
                      />
                      {/* <div className="circle">
                        <p className="circle-inner mb-0">SG</p>
                      </div> */}
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item onClick={handSingOut} data-test="HeaderNav-sing-out">Logout</Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </Col>
              </Col>
            </Row>
          </Col>
        </Container>
      </Navbar>
    </>
  );
};

const mapStateToProps = state => {
  return {
    searchText: state.searchText
  }
}

const mapDispatchToProps = dispatch => {
  return {
    addSearchText: (data) => dispatch(addSearchText(data)),
    addCoeList: (data) => dispatch(addCoeList(data)),
    addAllCoEsCount: (data) => dispatch(addAllCoEsCount(data)),
    addUserId: (data) => dispatch(addUserId(data))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(HeaderNav);
