import React from 'react';
import { Col, Button } from "react-bootstrap";
import styles from "../allcoegrid.module.scss";
import like from "../../../assets/images/thumb.svg";
import liked from "../../../assets/images/thumb-fill.svg";
import { apis } from '../../../services/Api';




function IdeaCardLike(props) {
    const [isLiked, setIsLiked] = React.useState(props.data.is_liked);
    const [likeCount, setLikeCount] = React.useState(props.data.like_count);
    const [cid] = React.useState(props.data.cid);
    const [iid] = React.useState(props.data.iid);

    const likeIdea = async () => {
        // console.log("like idea");
        let result = await apis.like(cid, iid, 1);
        setIsLiked(1);
        setLikeCount(result.count);
    }

    const unLikeIdea = async () => {
        // console.log("unlike idea");
        let result = await apis.like(cid, iid, 0);
        setIsLiked(0);
        setLikeCount(result.count);
    }

    return (
        <React.Fragment>
            <Col md="auto" className="d-flex align-items-center">
                {isLiked == 1 ?
                    <Button
                        data-test="IdeaCardLike-unLikeIdea"
                        onClick={unLikeIdea}
                        variant="link"
                        className="d-flex py-0 align-items-center"
                    >
                        <img
                            alt="Like"
                            src={liked}
                            className={`${styles.like_img}`}
                        />
                        <span className="label p-2 py-0">
                            <b className="pe-1">{likeCount}</b>Likes
                        </span>
                    </Button>
                    :
                    <Button
                        data-test="IdeaCardLike-likeIdea"
                        onClick={likeIdea}
                        variant="link"
                        className="d-flex py-0 align-items-center"
                    >
                        <img
                            alt="Like"
                            src={like}
                            className={`${styles.like_img}`}
                        />
                        <span className="label p-2 py-0">
                            <b className="pe-1">{likeCount}</b>Likes
                        </span>
                    </Button>
                }

            </Col>
        </React.Fragment>
    );
}

export default IdeaCardLike;