import axios from 'axios';

import { apis } from './Api';

export function errorInterceptor() {
    axios.interceptors.response.use(null, (error) => {
        console.log(error);
        const { response, config } = error;
        if (!response) {
            // network error
            console.error(error);
            return;
        }

        if ([401].includes(response.status)) {
            if (config.url !== 'https://graph.microsoft.com/v1.0/groups/e0b494f3-8299-4dcd-9cee-852f31bcd017/drive/items/01T24TYXQXSXVURPUFQJD34QV4OBN2VGY3/children') {
                localStorage.clear()
                window.location.href = "/sign-in";
            }
        }



        const errorMessage = response.data?.message || response.statusText;
        console.error('ERROR:', errorMessage);
    });
}