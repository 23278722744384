import React from 'react';
import { useParams } from "react-router-dom";
import { apis } from '../../../../services/Api';
import styles from "../../coedetails.module.scss";
import like from "../../../../assets/images/thumb.svg";
import liked from "../../../../assets/images/thumb-fill.svg";

function Like(props) {
    const [isLiked, setIsLiked] = React.useState(props.data.is_liked);
    const [likeCount, setLikeCount] = React.useState(props.data.like_count);
    const [cmdid] = React.useState(props.data.cmd_id);
    //for retrieve params
    const params = useParams();


    const likeIdea = async () => {
        console.log("like idea");
        let result = await apis.commentLike(params.cid, params.iid, cmdid, 1);
        setIsLiked(1);
        setLikeCount(result.count);
    }

    const unLikeIdea = async () => {
        console.log("unlike idea");
        let result = await apis.commentLike(params.cid, params.iid, cmdid, 0);
        setIsLiked(0);
        setLikeCount(result.count);
    }

    return (
        <React.Fragment>
            {isLiked == 1 ?
                <span className={`${styles.card_title}`} onClick={unLikeIdea} data-test="IdeaDetailsLike-unLikeIdea" >
                    <img alt="Like" src={liked} className={`${styles.like_img}`} />
                </span>
                :
                <span className={`${styles.card_title}`} onClick={likeIdea} data-test="IdeaDetailsLike-likeIdea" >
                    <img alt="Like" src={like} className={`${styles.like_img}`} />
                </span>
            }
            <span className="ps-2">{likeCount}</span>
        </React.Fragment>);
}

export default Like;