import React from 'react';

function IdeaCardLob(props) {
    return (<React.Fragment>
        {props.lobs && props.lobs.map((lob, i) =>
            <span key={i} className="txt-tag" data-test="IdeaCardLob-tag">{lob.name}</span>
        )}
    </React.Fragment>);
}

export default IdeaCardLob;