export default function FileExtension(text) {
    let url = (text.split("?")[0]).toLowerCase();
    if (url.search("mp4") > 0) return 'mp4';
    if (url.search("mpeg") > 0) return 'mp4';
    if (url.search("mov") > 0) return 'mp4';
    if (url.search("pdf") > 0) return 'pdf';
    if (url.search("docx") > 0) return 'doc';
    if (url.search("doc") > 0) return 'doc';
    if (url.search("xlsx") > 0) return 'doc';
    if (url.search("xls") > 0) return 'doc';
    if (url.search("pptx") > 0) return 'doc';
    if (url.search("ppt") > 0) return 'doc';
    if (url.search("text") > 0) return 'doc';
    return 'link';
}

