import React from "react";
import { connect } from 'react-redux';
import Multiselect from 'multiselect-react-dropdown';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import styles from "./createip.module.scss";
import { Form, Button, Row, Col, Card } from "react-bootstrap";
import { WithContext as ReactTags } from "react-tag-input";
import pingray from "../../assets/images/pin-gray.svg";
import createip from "../../assets/images/create-img.svg";
import { apis } from '../../services/Api';
import FileExtension from '../../common/FileExtension/FileExtension';
import convertToEscape from '../../common/ConvertToEscape/ConvertToEscape';
import convertToUnEscape from '../../common/ConvertToUnEscape/ConvertToUnEscape';
import FileList from '../../common/FileList/FileList';
import { addAllCoEsCount, addCoeList } from "../../actions/SkillAction";

const KeyCodes = {
  comma: 188,
  enter: 13,
};

const delimiters = [KeyCodes.comma, KeyCodes.enter];


const CreateIP = (props) => {

  let [coeList, setCoeList] = React.useState([]);
  let [domainList, setDomainList] = React.useState([]);
  let [formState, setFormState] = React.useState({
    cid: null,
    iid: null,
    title: '',
    description: '',
    idea_artifact: [],
    idea_lobs: [],
    file: null,
    idea_tags: [],
    tagSuggestions: []
  });

  //for File list
  let [show, setShow] = React.useState(false);
  let [fileIndex, setFileIndex] = React.useState();
  let handleClose = () => setShow(false);
  let handleShow = (i) => { setFileIndex(i); setShow(true) };


  React.useEffect(() => {
    console.log("React.useEffect");
    // if (isSearching) return;
    apiCall();
  }, []);

  React.useEffect(() => {
    if (formState.idea_lobs.length > 0) {
      document.getElementById("search_input").removeAttribute("required");
    } else {
      document.getElementById("search_input").setAttribute("required", "");
    }
  }, [formState.idea_lobs])

  const handleDelete = (i) => {
    let tempTags = formState.idea_tags.filter((tag, index) => index !== i)
    // setTags(tags.filter((tag, index) => index !== i));
    setFormState({
      ...formState,
      idea_tags: tempTags,
    })
  };

  const handleAddition = (tag) => {
    setFormState({
      ...formState,
      idea_tags: [...formState.idea_tags, tag],
    })
    // setTags([...tags, tag]);
  };

  const apiCall = async () => {
    let [resultCategories, resultLobs, resultTags] = await Promise.all([apis.getCategories(), apis.getLobs(), apis.getTags()]);
    setCoeList(resultCategories.data);
    setDomainList(resultLobs.data);
    //re-structure response data
    let renameNameToText = [];
    resultTags.data.forEach((element, i) => {
      renameNameToText.push({
        id: `${i}`,
        text: convertToUnEscape(element.name)
      });
    });

    //! if form edit mod set all state at once other wise give bug. 
    if (props.location.state.edit) {
      let { data: ideaData } = await apis.getIdea(props.location.state.cid, props.location.state.iid);
      console.log(ideaData);
      //re-structure response data
      let ideaTagsConvertToArrayObj = [];
      ideaData.tags.forEach((element, i) => {
        ideaTagsConvertToArrayObj.push({
          id: `${i}`,
          text: convertToUnEscape(element.name)
        });
      });
      setFormState({
        ...formState,
        title: ideaData.title,
        description: ideaData.description,
        cid: props.location.state.cid,
        iid: ideaData.id,
        idea_artifact: ideaData.idea_artifact,
        idea_lobs: ideaData.lobs,
        idea_tags: ideaTagsConvertToArrayObj,
        tagSuggestions: renameNameToText
      })
    } else {
      // console.log(renameNameToText);
      setFormState({
        ...formState,
        tagSuggestions: renameNameToText
      });
    }


  }

  const onSelectLob = (selectedList, selectedItem) => {
    setFormState({
      ...formState,
      idea_lobs: selectedList
    });
  }

  const onRemoveLob = (selectedList, removedItem) => {
    setFormState({
      ...formState,
      idea_lobs: selectedList
    })
  }

  //This method handle add event for artfact
  const addArtifact = (e) => {
    e.preventDefault();
    setFormState({
      ...formState,
      idea_artifact: [...formState.idea_artifact, {
        "title": "",
        "description": "",
        "artifact_type": "",
        "url": ""
      }
      ]
    });
  }

  //This method handle delete event for artfact 
  const deleteArtifact = (i) => {
    console.log(i);
    let ideaArtifact = [...formState.idea_artifact];
    ideaArtifact.splice(i, 1);
    setFormState({
      ...formState,
      idea_artifact: ideaArtifact
    });
  }

  //This method handle change event for artfact title
  const changeArtifactTitle = (e, i) => {
    let ideaArtifact = [...formState.idea_artifact];
    ideaArtifact[i].title = convertToEscape(e.currentTarget.value);
    setFormState({
      ...formState,
      idea_artifact: ideaArtifact
    });
  }

  //This method handle change event for artfact URL
  const changeArtifactUrl = (value, i) => {
    let ideaArtifact = [...formState.idea_artifact];
    ideaArtifact[i].url = convertToEscape(value);
    ideaArtifact[i].artifact_type = FileExtension(value);
    setFormState({
      ...formState,
      idea_artifact: ideaArtifact
    });
  }

  //This method handle change event for artfact URL
  const changeArtifactUrlFromTeamsFileList = (value, i) => {
    console.log(value, i)
    let ideaArtifact = [...formState.idea_artifact];
    ideaArtifact[i].url = convertToEscape(value['webUrl']);
    ideaArtifact[i].artifact_type = FileExtension(value['webUrl']);
    ideaArtifact[i].drive_id = value['id'];
    ideaArtifact[i].name = value['name'];
    ideaArtifact[i].downloadUrl = value['@microsoft.graph.downloadUrl'];
    setFormState({
      ...formState,
      idea_artifact: ideaArtifact
    });
  }

  const changeDescription = (e, i) => {
    let ideaArtifact = [...formState.idea_artifact];
    ideaArtifact[i].description = convertToEscape(e.currentTarget.value);
    setFormState({
      ...formState,
      idea_artifact: ideaArtifact
    });
  }

  //This method handle change event for idea title
  const changeIdeaTitle = (e) => {
    setFormState({
      ...formState,
      title: convertToEscape(e.currentTarget.value)
    })
  }

  //This method handle change event for idea description  
  const changeIdeaDescription = (e) => {
    setFormState({
      ...formState,
      description: convertToEscape(e.currentTarget.value)
    })
  }

  const changeIdeaCoe = (e) => {
    setFormState({
      ...formState,
      cid: e.currentTarget.value
    })
  }

  // Send form data to server
  const onFormSubmit = async (e) => {
    e.preventDefault();

    if (formState.idea_artifact.length == 0) {
      NotificationManager.warning('Idea Should have at least one artifact.');
      return;
    }

    if (props.location.state.edit) {
      await apis.updateIdea(formState.cid, formState.iid, formState);
    } else {
      await apis.addIdea(formState.cid, formState);
    }
    await getUpdatedCategories();
    goToPage();
  }

  const goBack = () => {
    props.history.goBack()
  }

  const goToPage = () => {
    props.history.push(`/${formState.cid}/ideas`);
  }

  const getUpdatedCategories = async () => {
    let result = await apis.getCategories(), allCoeCount = 0;
    // setCoeList(result.data);
    props.addCoeList(result.data);
    result.data.forEach(element => {
      if (!Number(element.idea_count)) return;
      allCoeCount = Number(element.idea_count) + allCoeCount;
    });
    props.addAllCoEsCount(allCoeCount);
  }

  return (
    <React.Fragment>
      <div className="container-fluid py-5 px-4">
        <Row>
          <Col md={12} lg={8}>
            <h1 className="d-flex align-items-center">
              {/* <a href="/">
              <i className="bi bi-arrow-left pe-2 back-arrow"></i>
            </a> */}
              Create IP
            </h1>
            <Form className="c-form container-wht-bg" onSubmit={(e) => onFormSubmit(e)} data-test="ip-artifact-form">
              <Form.Group className="mb-3">
                <Form.Label>Title</Form.Label>
                <Form.Control
                  id="ip-title"
                  type="text"
                  placeholder="Lorem Ipsum is simply dummy text of the printing"
                  required={true}
                  value={convertToUnEscape(formState.title)} onChange={(e) => changeIdeaTitle(e)}
                />
              </Form.Group>

              <Row className="flex-sm-wrap">
                <Col lg={6} md={6} xs={12}>
                  <Form.Group className="mb-3">
                    <Form.Label>Select Folder</Form.Label>
                    <Form.Select onChange={changeIdeaCoe} required={true} id="ip-coe">
                      <option value="" disabled selected>Select your option</option>
                      {coeList && coeList.map((e, i) =>
                        <option key={i} value={e.id} selected={e.id === formState.cid ? 'selected' : null}>{e.title}</option>
                      )}
                    </Form.Select>
                  </Form.Group>
                </Col>
                <Col lg={6} md={6} xs={12}>
                  {/* <Form.Group className="mb-3">
                  <Form.Label>Select Domain</Form.Label>
                  <Form.Select>
                    <option>HRTech, HealthTech</option>
                  </Form.Select>
                </Form.Group> */}

                  <Form.Group className="mb-3">
                    <Form.Label>Select Domain</Form.Label>
                    <Multiselect
                      options={domainList} // Options to display in the dropdown
                      onSelect={onSelectLob} // Function will trigger on select event
                      onRemove={onRemoveLob} // Function will trigger on remove event
                      displayValue="name" // Property name to display in the dropdown options
                      selectedValues={formState.idea_lobs}

                    />
                  </Form.Group>
                </Col>
              </Row>

              <Form.Group className="mb-3">
                <Form.Label>Description</Form.Label>
                <Form.Control id="ip-description" required={true} as="textarea" rows={3} value={convertToUnEscape(formState.description)} onChange={(e) => changeIdeaDescription(e)} />
              </Form.Group>

              <Row className="d-flex align-items-center mb-3">
                <Col>
                  <h5 className="ps-0 mb-0 mt-3">Add artifact(s)</h5>
                </Col>
              </Row>


              {formState.idea_artifact.map((e, i) => <Row key={i}>
                <Col md={12} className="mt-3">
                  <Card className={`mb-3 p-4 pb-0 ${styles.card_white}`}>
                    <div className="row">
                      <div className="col-md-12 p-4">
                        <Row>
                          <Form.Group className="mb-3">
                            <Form.Control
                              type="text"
                              placeholder="Title"
                              required={true}
                              value={convertToUnEscape(formState.idea_artifact[i].title)}
                              onChange={(e) => changeArtifactTitle(e, i)}
                              data-test="ip-artifact-title"
                            />
                          </Form.Group>
                          <Form.Group className="mb-3">
                            <Form.Control
                              as="textarea"
                              rows={3}
                              // required={true}
                              placeholder="Description"
                              value={convertToUnEscape(formState.idea_artifact[i].description ? formState.idea_artifact[i].description : '')}
                              onChange={(e) => changeDescription(e, i)}
                              data-test="ip-artifact-description"
                            />
                          </Form.Group>
                          <Col className="d-flex align-items-center position-relative">
                            <Form.Group className="mb-3 col-md-8 col-lg-8 pe-3">
                              <span className={`${styles.pin_overlap}`}>
                                <img
                                  alt="pin"
                                  src={pingray}
                                  className={`${styles.img_pin}`}
                                />
                              </span>
                              <Form.Control
                                type="text"
                                placeholder="URL"
                                required={true}
                                className={`${styles.field_ico}`}
                                value={convertToUnEscape(formState.idea_artifact[i].url)}
                                onChange={(e) => changeArtifactUrl(e.currentTarget.value, i)}
                                data-test="ip-artifact-url"
                              />
                            </Form.Group>
                            <span className="mb-3">Or</span>
                            <Form.Group className="mb-3 col-md-4 col-lg-4 ps-3">
                              <Button variant="primary" onClick={() => handleShow(i)}>
                                Choose File
                              </Button>
                            </Form.Group>
                          </Col>
                        </Row>
                        <Row>
                          <Col className="py-4 btn-row">

                            <Button variant="secondary" className="ripple" onClick={() => deleteArtifact(i)} data-test="ip-delete-artifact">
                              Remove
                            </Button>

                          </Col>
                        </Row>
                      </div>
                    </div>
                  </Card>
                </Col>
              </Row>
              )}



              {/* Edit view end */}
              <Col className="d-flex justify-content-end mb-3 add-ico">
                <Button
                  variant="primary"
                  className="ripple btn-bordered-c d-flex align-items-center"
                  onClick={addArtifact}
                  id="ip-add-artifact"
                >
                  <i className="bi bi-plus "></i>Artifacts
                </Button>
              </Col>

              <Row>
                <Col md={12} className="my-3">
                  <h5 className="ps-0 mb-0 mt-3">Add tags</h5>
                </Col>
                <Col>
                  {/* <span className="badge bg-info text-dark selected">
                  Integration
                </span>
                <span className="badge bg-info text-dark selected">Chat Bot</span>

                <Button variant="link add-tags">
                  <i className="bi bi-plus"></i>
                </Button> */}
                  {/* {formState.tagSuggestions.length > 0 ? */}
                  <ReactTags
                    tags={formState.idea_tags}
                    delimiters={delimiters}
                    suggestions={formState.tagSuggestions}
                    handleDelete={handleDelete}
                    handleAddition={handleAddition}
                    inputFieldPosition="bottom"
                    className={styles.tags}
                    autofocus={false}
                  />
                  {/* : '' */}
                  {/* } */}
                </Col>
              </Row>

              <Row>
                <Col className="py-4 btn-row">
                  <Button variant="primary" className="ripple" type="submit">
                    Save
                  </Button>

                  <Button variant="secondary" className="ripple" onClick={() => goBack()} data-test="CreateIP-Cancel" id="CreateIP-Cancel">
                    Cancel
                  </Button>
                </Col>
              </Row>
            </Form>
          </Col>
          <Col
            md={4}
            className={`d-none d-lg-flex justify-content-center align-items-center d-flex ${styles.img_wrapper}`}
          >
            <div>
              <img
                alt="Create IP"
                src={createip}
                className={`${styles.bg_img_create}`}
              />
            </div>
          </Col>
        </Row>
      </div>
      <NotificationContainer />
      <FileList show={show} handleClose={handleClose} changeArtifactUrl={changeArtifactUrlFromTeamsFileList} fileIndex={fileIndex} />
    </React.Fragment>
  );
};

// export default CreateIP;

const mapStateToProps = state => {
  return {
  }
}

const mapDispatchToProps = dispatch => {
  return {
    addCoeList: (data) => dispatch(addCoeList(data)),
    addAllCoEsCount: (data) => dispatch(addAllCoEsCount(data))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateIP);
