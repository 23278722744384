import React, { useRef } from "react";
import styles from "./popoverinfo.module.scss";
import { Popover, Col, Overlay, Row, Container } from "react-bootstrap";

import info from "../../assets/images/info.svg";
import convertToUnEscape from '../../common/ConvertToUnEscape/ConvertToUnEscape';
import timeDifference from '../../common/Time/Time';

const PopoverInfo = (props) => {
  const [show, setShow] = React.useState(false);
  const [target, setTarget] = React.useState(null);
  const ref = useRef(null);

  const handleClick = (event) => {
    setShow(!show);
    setTarget(event.target);
  };
  return (
    <div ref={ref} className={`${styles.popover_info}`}>
      <a onClick={handleClick}>
        <img alt="Info" src={info} className={` cursor-pointer ${styles.info_img}`} /> {/* 0403 changed img <i> */}
      </a>
      <Overlay
        show={show}
        target={target}
        placement="bottom"
        container={ref}
        containerPadding={20}
      >
        <Popover id="popover-contained">
          <Popover.Body>
            <Container>
              <Row>
                <Col>
                  <div className="description d-flex mt-2">
                    <span className="text-truncate-multiline">
                      {convertToUnEscape(props.data.description)}
                    </span>
                  </div>
                </Col>

                <Col md={12} className="d-flex justify-content-start mt-2">
                  <div className="tag-gray">{timeDifference(props.data.updated_at)}</div>
                </Col>
              </Row>
            </Container>
          </Popover.Body>
        </Popover>
      </Overlay>
    </div>
  );
};

export default PopoverInfo;
