export default function timeDifference(created_at) {
    let diffInMilliSeconds = Math.abs(new Date() - new Date(created_at)) / 1000;

    // calculate days
    const days = Math.floor(diffInMilliSeconds / 86400);
    diffInMilliSeconds -= days * 86400;
    // console.log('calculated days', days);

    // calculate hours
    const hours = Math.floor(diffInMilliSeconds / 3600) % 24;
    diffInMilliSeconds -= hours * 3600;
    // console.log('calculated hours', hours);

    // calculate minutes
    const minutes = Math.floor(diffInMilliSeconds / 60) % 60;
    diffInMilliSeconds -= minutes * 60;
    // console.log('minutes', minutes);

    let difference = '';
    if (days > 0) {
        difference += (days === 1) ? `${days} day` : `${days} days`;
        return difference;
    }

    if (hours > 0) {
        difference += (hours === 0 || hours === 1) ? `${hours} hr` : `${hours} hrs`;
        return difference;
    }

    difference += (minutes === 0 || hours === 1) ? `${minutes} min` : `${minutes} mins`;

    return difference;
    // return `${Math.round(Math.abs(new Date() - new Date(created_at)) / 36e5)} hrs ago`
}

