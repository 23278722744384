import React from 'react';
import HashParameters from '../../common/HashParameters/HashParameters';
import { apis } from '../../services/Api';
import { logInEvent } from '../../services/Auth';
import { HashLoader } from "react-spinners";
import styles from "./signin.module.scss";
import { Row} from "react-bootstrap";

function SignInEnd(props) {

    React.useEffect(() => {
        localStorage.removeItem("auth.error");
        let hashParams = HashParameters();

        if (hashParams["error"]) {
            localStorage.setItem("auth.error", JSON.stringify(hashParams));
            props.history.push({
                pathname: `/sign-in`
            });
        } else if (hashParams["access_token"]) {
            let key = "auth.result";
            localStorage.setItem(key, JSON.stringify({
                idToken: hashParams["id_token"],
                accessToken: hashParams["access_token"],
                tokenType: hashParams["token_type"],
                expiresIn: hashParams["expires_in"],
                expiresTime: new Date().getTime() + hashParams["expires_in"] * 1000 // this filed is use for PrivateRoute
            }));

            apis.apiAuthenticate(hashParams["access_token"]).then((response) => {
                console.log(response);
                logInEvent.next(true);
                localStorage.setItem('auth.userType', response.is_admin);
                if (localStorage.getItem("redirectTo")) {
                    props.history.push({
                        pathname: `${localStorage.getItem("redirectTo")}`
                    });
                } else {
                    props.history.push({
                        pathname: `/`
                    });
                }
            })
        } else {
            localStorage.setItem("auth.error", JSON.stringify(hashParams));
            props.history.push({
                pathname: `/sign-in`
            });
        }
    }, []);

    return ( <React.Fragment>
        <div className="container-fluid px-4">
            <Row className={`${styles.container_center}`}>

                <div className="col-md-6 col-lg-3 d-flex justify-content-center">
                <HashLoader color="#06568f" />
                </div>
            </Row>
        </div>
    </React.Fragment >);
}



export default SignInEnd;