const initialState = {
    defaultCoe: {
        id: "allips",
        title: "All IPs",
        idea_count: 0
    },
    coeList: [],
    searchText: null,
    userId:null
}


const RootReducer = (state = initialState, action) => {
    console.log(state, action);
    switch (action.type) {
        case "ADD_COE_LIST":
            return {
                ...state,
                coeList: action.coeList
            }
        case "ADD_ALL_COE_COUNT":
            return {
                ...state,
                defaultCoe: {
                    ...state.defaultCoe,
                    idea_count: action.count
                }
            }
        case "ADD_SEARCH_TEXT":
            return {
                ...state,
                searchText: action.text
            }
        case "ADD_USER_ID":
            return {
                ...state,
                userId: action.id
            }
        default:
            return state
    }
}

export default RootReducer;