import React from "react";
import {
  Modal,
  ListGroup,
  Button
} from "react-bootstrap";
import { apis } from "../../services/Api";
import docx from "../../assets/images/docx.svg";
import ppt from "../../assets/images/ppt.svg";
import pdf from "../../assets/images/pdf.svg";
import folder from "../../assets/images/folder.svg";

import textgray from "../../assets/images/textgray.svg";
import pingray from "../../assets/images/pin-gray.svg";
import powerpointgray from "../../assets/images/powerpoint-gray.svg";
import videogray from "../../assets/images/video-gray.svg";
import FileExtension from "../FileExtension/FileExtension";

const initFilepath = [{ path: "Home", id: `${process.env.REACT_APP_ROOT_FOLDER_ID}` }];

function FileList(props) {
  let [fileList, setFileList] = React.useState([]);
  let [filePath, setFilePath] = React.useState(initFilepath);

  React.useEffect(() => {
    apiCall(null,true); //call root api
    setFilePath(initFilepath); // for re-sate every time popup open and close
  }, [props.show]);

  const apiCall = async (id,root) => {
    console.log(filePath);
    let { value } = await apis.getFileList(id,root);
    setFileList(value);
  };

  const fileDetails = async (id) => {
    let fileDetails = await apis.getFileDetails(id);
    console.log(fileDetails);
    props.changeArtifactUrl(fileDetails, props.fileIndex);
    props.handleClose();
  };

  const goUpFolder = (data) => {
    let newFilePath = [...filePath];
    newFilePath.push({ path: data.name, id: data.id });
    setFilePath(newFilePath);
    apiCall(data.id);
  };

  const goDownFolder = (i, data) => {
    let newFilePath = [...filePath];
    newFilePath = newFilePath.slice(0, i + 1);
    setFilePath(newFilePath);
    if(i===0){
      apiCall(data.id,true); //call root api
    }else{
      apiCall(data.id,false);
    }
  };

  const fileOrFolder = (data) => {
    if (data.hasOwnProperty("folder")) {
      return (
        <ListGroup.Item
          action
          variant="warning"
          onClick={() => goUpFolder(data)}
          className="d-flex"
        >
          <span className="pe-2">
            <img alt="Folder" src={folder} className="file-type-ico" />
          </span>
          {data.name}
        </ListGroup.Item>
      );
    } else {
      return (
        <ListGroup.Item
          action
          key={data.id}
          onClick={() => fileDetails(data.id)}
          className="d-flex"
        >
          {" "}
          <span className="pe-2">
            <img alt="Doc" src={extensionImage(data.name)} className="file-type-ico" />
          </span>
          <span>{data.name}</span>
        </ListGroup.Item>
      );
    }
  };

  const extensionImage = (name) => {
    let filetype = FileExtension(name);
    switch (filetype) {
      case 'mp4':
        return videogray;
        break;

      case 'pdf':
        return powerpointgray;
        break;

      case 'doc':
        return textgray;
        break;

      default:
        return pingray;
        break;
    }
  }

  return (
    <React.Fragment>
      <Modal aria-labelledby="contained-modal-title-vcenter"
        centered scrollable={true} show={props.show} onHide={props.handleClose}>
        <Modal.Header closeButton>Files</Modal.Header>
        <Modal.Body>
          <div className="mb-2 breadcrumb">
            {/* <a routerLink="/home">home</a> */}
            {filePath.map((e, i, row) => {
              if (i + 1 === row.length) {
                return <a key={i}>{e.path}</a>;
              } else {
                return (
                  <a
                    key={i}
                    className="cursor-pointer px-2"
                    onClick={() => goDownFolder(i, e)}
                  >
                    {e.path} /
                  </a>
                );
              }
            })}
          </div>
          <ListGroup>{fileList.map((e) => fileOrFolder(e))}</ListGroup>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.handleClose}>
            Close
          </Button>
          {/* <Button variant="primary" onClick={props.handleClose}>
                        Save Changes
                    </Button> */}
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
}

export default FileList;
