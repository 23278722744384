import React from 'react';
import { Button } from "react-bootstrap";
import favfill from "../../../assets/images/fav.svg";
import fav from "../../../assets/images/fav-plain.svg";
import styles from "../coedetails.module.scss";
import { apis } from '../../../services/Api';


function CoeDetailsFavorite(props) {
    const [isFavorite, setFavorite] = React.useState(props.data.isFavorite);
    const [cid] = React.useState(props.data.cid);
    const [iid] = React.useState(props.data.iid);

    const favoriteIdea = async () => {
        console.log("like idea");
        let result = await apis.favorite(cid, iid, 1);
        setFavorite(1);
    }

    const unFavoriteIdea = async () => {
        console.log("unlike idea");
        let result = await apis.favorite(cid, iid, 0);
        setFavorite(0);
    }

    return (
        <React.Fragment>
            <Button
                data-test="Favorite-button"
                variant="link"
                className="d-flex pt-0 align-items-center divider-right all-list"
            >

                {isFavorite == 1 ?

                    <img
                        onClick={unFavoriteIdea}
                        alt="Favorite"
                        src={favfill}
                        className={`${styles.fav_img}`}
                    />
                    :
                    <img
                        onClick={favoriteIdea}
                        alt="Favorite"
                        src={fav}
                        className={`${styles.fav_img}`}
                    />
                }

                <span className="label p-2 py-0">My Favorite</span>
            </Button>
        </React.Fragment>
    );
}

export default CoeDetailsFavorite;