import React from 'react';
import { Col, Button, Card } from "react-bootstrap";
import styles from "../coedetails.module.scss";
import text from "../../../assets/images/text.svg";
import powerpoint from "../../../assets/images/powerpoint.svg";
import video from "../../../assets/images/video.svg";
import pin from "../../../assets/images/pin.svg";
import convertToUnEscape from '../../../common/ConvertToUnEscape/ConvertToUnEscape';
import axios from 'axios';

function Artifact(props) {

    const fileTypeLogo = (type) => {
        switch (type) {
            case 'mp4':
                return <img
                    data-test="IdeaCardArtifactList-video"
                    alt="text"
                    src={video}
                    className={`${styles.img_text}`}
                />
            case 'pdf':
                return <img
                    data-test="IdeaCardArtifactList-powerpoint"
                    alt="text"
                    src={powerpoint}
                    className={`${styles.img_text}`}
                />

            case 'doc':
                return <img
                    data-test="IdeaCardArtifactList-text"
                    alt="text"
                    src={text}
                    className={`${styles.img_text}`}
                />

            default:
                return <img
                    data-test="IdeaCardArtifactList-pin"
                    alt="text"
                    src={pin}
                    className={`${styles.img_text}`}
                />

        }
    }

    return (
        <React.Fragment key={props.key}>
            <Col md={12} className="mt-3">
                <Card className={`mb-3 ${styles.card_white}`}>
                    <div className="row d-flex justify-content-between">
                        <div
                            className={`col-auto d-flex align-items-center ${styles.ico_bg}`}
                        >
                            {/* <img
                                alt="text"
                                src={text}
                                className={`${styles.img_text}`}
                            /> */}
                            {fileTypeLogo(props.data.artifact_type)}
                        </div>
                        <div className="col-7 col-sm-8 col-lg-9 p-4">
                            <div
                                className={`col-7 col-sm-8 col-lg-9 ${styles.card_title}`}
                            >
                                {convertToUnEscape(props.data.title)}
                            </div>
                            {props.data?.description &&
                                <div className="description mt-2">
                                    {convertToUnEscape(props.data.description)}
                                </div>
                            }
                        </div>
                        <div className="col-3 col-sm-2 col-lg-2 d-flex align-items-start mt-3 justify-content-end">
                            <div className="d-flex pe-4">
                                <Button
                                    variant="primary"
                                    // data-toggle="modal" data-target={`#show-details-modal`}
                                    // type='button'
                                    className="ripple btn-bordered-c"
                                    onClick={() => {
                                        
                                        if(props?.data?.cq_name){ // if file name is present then send the selected artifact to parent(coeDetails) to send the data to modal
                                            (function(){
                                                props.data.cq_name && axios.get( `${process.env.REACT_APP_API_ENDPOINT}/idea/cq_file_content/` + props.data.cq_name ).then(async (response) => {
                                                        props.data.modalContent = response.data.data
                                                        props.updateModelData(props.data  )
                                                }).catch( err => console.log("errr1\n", err) )
                                            } ())
                                        }
                                        else{ // redirect to link
                                            window.open(convertToUnEscape(props.data.url), "_blank")
                                        }
                                    }}
                                > 
                                    View
                                </Button>
                            </div>
                        </div>
                    </div>
                </Card>
            </Col>
        </React.Fragment>
    );
}

export default Artifact;