import React from 'react';
import { Row, Col, Container } from "react-bootstrap";
import styles from "../allcoelist.module.scss";
import textgray from "../../../assets/images/textgray.svg";
import pingray from "../../../assets/images/pin-gray.svg";
import powerpointgray from "../../../assets/images/powerpoint-gray.svg";
import videogray from "../../../assets/images/video-gray.svg";

function IdeaCardArtifactList(props) {

    return (
        <React.Fragment>
            <Col md={12}> {/* 0403 removed className pt-2 */}
                <Container className="px-0 d-flex justfy-content-start">
                    <Row className={`${styles.ico_section}`}>
                        <Col className="divider-right">
                            <Col>
                                <span>
                                    <img
                                        data-test="IdeaCardArtifactList-text"
                                        alt="text"
                                        src={textgray}
                                        className={`${styles.img_text}`}
                                    />
                                </span>
                            </Col>
                            <Col>
                                <span data-test="IdeaCardArtifactList-text-count">{props.data.doc_count}</span>
                            </Col>
                        </Col>
                        <Col className="divider-right">
                            <Col>
                                <span>
                                    <img
                                        data-test="IdeaCardArtifactList-video"
                                        alt="video"
                                        src={videogray}
                                        className={`${styles.img_video}`}
                                    />
                                </span>
                            </Col>
                            <Col>
                                <span data-test="IdeaCardArtifactList-video-count">{props.data.mp4_count}</span>
                            </Col>
                        </Col>

                        <Col className="divider-right">
                            <Col>
                                <Col>
                                    <span>
                                        <img
                                            data-test="IdeaCardArtifactList-pin"
                                            alt="pin"
                                            src={pingray}
                                            className={`${styles.img_pin}`}
                                        />
                                    </span>
                                </Col>
                                <Col>
                                    <span data-test="IdeaCardArtifactList-pin-count">{props.data.link_count}</span>
                                </Col>
                            </Col>
                        </Col>
                        <Col>
                            <Col>
                                <span>
                                    <img
                                        data-test="IdeaCardArtifactList-powerpoint"
                                        alt="powerpoint"
                                        src={powerpointgray}
                                        className={`${styles.img_powerpoint}`}
                                    />
                                </span>
                            </Col>

                            <Col>
                                <span data-test="IdeaCardArtifactList-powerpoint-count">{props.data.pdf_count}</span>
                            </Col>
                        </Col>
                    </Row>
                </Container>
            </Col>
        </React.Fragment>
    );
}

export default IdeaCardArtifactList;