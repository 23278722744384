import { Subject } from 'rxjs';

let logInEvent = new Subject();

function getTokenDetails() {
    let userData = localStorage.getItem("auth.result");
    // console.log("Getting user details.");
    // console.log(userData)
    return JSON.parse(userData);
}

function isLogin() {
    let data = getTokenDetails();
    if (data) {
        if (new Date().getTime() > data.expiresTime) {
            console.log("user not login, token expire");
            return false;
        }
        else {
            console.log("user login");
            return true;
        }
    } else {
        console.log("user not login");
        return false;
    }
}

function IsAdmin() {
    let userData = localStorage.getItem("auth.userType") || false;
    return Number(JSON.parse(userData) ? 0 : 1);
}

export {
    getTokenDetails,
    isLogin,
    logInEvent,
    IsAdmin
}