import React from "react";
import { useParams } from 'react-router-dom';
import styles from "./allcoelist.module.scss";
import { Row, Col, Container, Table, Form, Button } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import PopoverInfo from "../PopoverInfo/PopoverInfo";
import IdeaCardArtifactList from './ArtifactList/ArtifactList';
import LikeAndCmd from './LikeAndCmd/LikeAndCmd';
import Favorite from "../../common/Favorite/Favorite";
import IdeaCardLob from "../../common/Lob/Lob";
import IdeaCardTag from '../../common/Tag/Tag';

const AllCoEList = (props) => {
  let history = useHistory();
  //for retrieve params
  const params = useParams();

  return (
    <React.Fragment>
      <Row>
        <Col md={12}>
          <Table hover id="no-more-tables" className="w-100">
            <thead>
              <tr>

                <th className="two">
                  Name {/* 0403 removed <i> */}
                </th>
                <th className="three">Modified By</th>
                <th className="four">Artifacts</th>
                <th className="five">CoE</th>
                <th className="six">
                  Tags
                </th> {/* 0403 added th, just check what title should use. Removed first TH */}
                <th className="last">Actions</th>
              </tr>
            </thead>
            <tbody>
              {props.ideaList.map((e, i) =>
                <tr key={i}>

                  <td data-title="Name">
                    <Container fluid className="px-0">
                      <Row>
                        <Col md={12} data-test="AllCoEList-title" className={`d-flex ${styles.card_title}`} onClick={() => props.goToIdeaDetails(e.id)}>
                          <span
                            className="text-truncate-multiline cursor-pointer"
                          >
                            {props.convertToUnEscape(e.title)}
                          </span>
                        </Col>

                        <Row className="mt-3">
                          <Col>
                            <IdeaCardTag
                              data-test="AllCoEList-tag"
                              data={e.tags?.split(",").map((e) => { return { 'name': e } })}
                            />
                          </Col>
                        </Row>
                      </Row>
                    </Container>
                  </td>
                  <td data-title="Modified By">
                    <Row>
                      <Col md={12} className="profile-info">
                        <Col md={12} className="d-block d-md-flex">
                          <div>
                            {" "}
                            <img
                              src={e.display_image}
                              width="40"
                              className="rounded-circle"
                            />{" "}
                          </div>
                          <div className="text-start ps-3 col-md-6 col-lg-8">
                            <h5 className="mt-2 mb-0 d-flex">
                              <span className="text-truncate">
                                {e.display_name}
                              </span>
                            </h5>{" "}
                          </div>
                        </Col>
                      </Col>
                    </Row>
                  </td>
                  <td data-title="Artifacts">
                    <IdeaCardArtifactList
                      data-test="AllCoEList-artifact"
                      data={e} />
                  </td>
                  <td data-title="CoE">
                    <span className="font-weight-6">{props.convertToUnEscape(e.category_title)}</span>
                  </td>
                  <td data-title="Tags">
                    <Col>
                      <IdeaCardLob lobs={e.lob?.split(",").map((e) => { return { 'name': e } })} />
                    </Col>
                  </td> {/* 0403 added TD here */}
                  <td>
                    <Container fluid className="px-0 count">
                      <Row lg={2} xxl={4}> {/* 0403 removed className */}
                        <Favorite
                          data-test="AllCoEList-favorite"
                          data={{
                            isGridView: false,
                            isFavorite: e.is_favorite,
                            cid: params.cid,
                            iid: e.id
                          }}
                        />
                        <LikeAndCmd
                          data-test="AllCoEList-like-cmd"
                          data={e}
                          cid={params.cid}
                        />
                        <Col>
                          <PopoverInfo
                            data-test="AllCoEList-popoverInfo"
                            data={e}
                          />
                        </Col>
                      </Row>
                    </Container>
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default AllCoEList;
