import React, { useEffect, useState } from "react";
import convertToUnEscape from "../../common/ConvertToUnEscape/ConvertToUnEscape";
import styles from "./modal.module.scss";

const tData = `No data found.`

const SearchModal = (props) => {
  const [textData, setTextData] = useState(props.content); // Static data for temporary use

  const [currentPosition, setCurrentPosition] = useState(0);
  const [htmlELements, setHtmlELements] = useState([]);
  const [maxCount, setMaxCount] = useState(0);
  const [searchedItem, setSearchedItem] = useState(props.itemToSearch);
  
  const [secondaryCurrentPosition, setSecondaryCurrentPosition] = useState(0);
  const [secondaryHtmlELements, setSecondaryHtmlELements] = useState([]);
  const [secondaryMaxCount, setSecondaryMaxCount] = useState(0);
  const [secondarySearchedItem, setSecondarySearchedItem] = useState('');

  useEffect(() => {
    props.getModal(document.getElementById(`show-details-modal`))
  }, []);

  useEffect(() => {
    if (props.itemToSearch) {
      searchKeyword(props.content, props.itemToSearch)
    }
    else {
      setTextData(props.content);
      setSearchedItem(props.itemToSearch)
    }
  }, [ props.itemToSearch, props.content ])

  const searchSecondaryTag = (secondartSearch)=>{
    secondartSearch && searchKeyword(props.content, props.itemToSearch, secondartSearch);
  }

  useEffect(() => {
    setTimeout(() => {
      const el = document.getElementsByClassName(styles.keyItemListHalide);
      el[0]?.classList?.add(styles.keyItemListHalideSelected);
      setHtmlELements(el);
      
      const sel = document.getElementsByClassName(styles.secondaryKeyItemList);
      sel[0]?.classList?.add(styles.secondaryKeyItemListSelected);
      setSecondaryHtmlELements(sel);

    }, 0);
  }, [textData])


  const searchKeyword = async (data, keyword, secondaryKeyword) => {
    let count = 0
    var isValid = true;
    try {
      new RegExp(keyword, 'gi');
    } catch (e) {
      isValid = false;
    }
    if (isValid) {
      const exp = new RegExp(keyword, 'gi');
      const exp1 = new RegExp(secondaryKeyword, 'gi');
      count = data.match(exp)?.length
      const str = data.replaceAll(exp, (match) => {
        return `<span class='${styles.keyItemListHalide}'>` + match + `</span>`
      });
      
      setMaxCount(count || 0);
      setCurrentPosition(0);
      setSearchedItem(keyword)

       
      if(secondaryKeyword){
        const secondaryStr = str.replaceAll(exp1, (match) => {
          return `<span class='${styles.secondaryKeyItemList}'>` + match + `</span>`
        });
        const secondaryCount = str.match(exp1)?.length
        setSecondaryMaxCount(secondaryCount || 0);
        setSecondaryCurrentPosition(0);
        setSecondarySearchedItem(secondaryKeyword)
        return setTextData(secondaryStr);
      }
      setTextData(str)
      return true
    } else {
      return false;
    }
  }

// const searchSecondaryKeyword = async (data, keyword, count) => {
//   var isValid = true;
//   try {
//     new RegExp(keyword, 'gi');
//     new RegExp(searchedItem , 'gi');
//   } catch (e) {
//     isValid = false;
//   }
//   if (isValid) {
//     const exp = new RegExp(keyword, 'gi');
//     const exp1 = searchedItem && new RegExp(searchedItem, 'gi');
//     count = data.match(exp)?.length
//     const str = data.replaceAll(exp, (match) => {
//       return `<span class='${styles.secondaryKeyItemList}'>` + match + `</span>`
//     });
//     setTextData(str);
//     setSecondaryMaxCount(count || 0);
//     setSecondaryCurrentPosition(0);
//   //   return true
//   // } else {
//   //   return false;
//   }
// }

  const iterate = (direction) => {
    let newPosition
    if (direction === "up") {
      newPosition = currentPosition + 1;
      if (maxCount > newPosition) {
        htmlELements[newPosition]?.classList?.add(styles.keyItemListHalideSelected);
        htmlELements[currentPosition]?.classList?.remove(styles.keyItemListHalideSelected);
        setCurrentPosition(newPosition);
      }
    } else if (direction === "down") {
      newPosition = currentPosition - 1;
      if (newPosition >= 0) {
        htmlELements[newPosition]?.classList?.add(styles.keyItemListHalideSelected);
        htmlELements[currentPosition]?.classList?.remove(styles.keyItemListHalideSelected);
        setCurrentPosition(newPosition);
      }
    }
    scrollTOView(htmlELements[newPosition]);
  }

  const secondaryIterate = (direction) => {
    let newPosition
    if (direction === "up") {
      newPosition = secondaryCurrentPosition + 1;
      if (secondaryMaxCount > newPosition) {
        secondaryHtmlELements[newPosition]?.classList?.add(styles.secondaryKeyItemListSelected);
        secondaryHtmlELements[secondaryCurrentPosition]?.classList?.remove(styles.secondaryKeyItemListSelected);
        setSecondaryCurrentPosition(newPosition);
      }
    } else if (direction === "down") {
      newPosition = secondaryCurrentPosition - 1;
      if (newPosition >= 0) {
        secondaryHtmlELements[newPosition]?.classList?.add(styles.secondaryKeyItemListSelected);
        secondaryHtmlELements[secondaryCurrentPosition]?.classList?.remove(styles.secondaryKeyItemListSelected);
        setSecondaryCurrentPosition(newPosition);
      }
    }
    scrollTOView(secondaryHtmlELements[newPosition]);
  }


  const scrollTOView = (currentValue) => {
    currentValue?.scrollIntoView({
      behavior: 'smooth',
      block: "center"
    });
  }

  const toggleModel = () => {
    props.modalRef?.current?.toggle()
  }
  return (
    <>
      <div >
        <button type="button" hidden id='open-details-model' className="btn btn-primary hidden" data-toggle="modal" data-target={`#show-details-modal`}>
          Launch modal
        </button>

        <div className="modal fade" id={`show-details-modal`} tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
          <div className={`${styles.myModel} modal-dialog modal-dialog-centered`} role="document">
            <div className={`${styles.modelContent} modal-content`}>
              <div className={`${styles.mHeader} modal-header`}>
                <h5 className={`${styles.mLabel} modal-title`} id="exampleModalLongTitle">
                  {props.title}
                </h5>
                <div onClick={()=>window.open(props.redirectUrl, "_blank")} className={`${styles.btn}`}>
                  <input type="submit" className={`${styles.btnDoc}`} value="View Document" />
                </div>
                <button onClick={toggleModel} type="button" className={`${styles.cbtn} close`} data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>

              <div className={`modal-body model-body-scrool`}>
                <div class="form_container">
                  <div class="row">
                    <div class="col-md-3 ">
                      <div class="demo">
                        <div class="accordion" id="myAccordion">
                          <div className={`${styles.cbtn} accordion-item`} >
                          <span class="accordion-header" id="headingOne">
                                    <button type="button" className={`${styles.ptitlefs} ${styles.ptitle} ${styles.cbtn} ${styles.accordionbutton} collapsed`} data-bs-toggle="collapse" data-bs-target="#collapseOne">Other Tags</button>									
                                </span>
                                <div id="collapseOne" class="accordion-collapse collapse" data-bs-parent="#myAccordion">
                                  <div class="cardbody">
                                  <div id="collapseOne" class="card-body collapse" data-parent="#accordion" >
                                  {/* <div className={`${styles.pnew}`}>
                                    <div className={`${styles.pborderbb}`} role="tab" id="headingTwo">
                                      <div className={`${styles.ptitlefsl}`} >
                                        <a className={`${styles.ptitle} collapsed `} >
                                          Javascript <div className={`${styles.circlediv}`}><div className={`${styles.circlespan}`}>9</div></div>
                                        </a>
                                      </div>
                                    </div>
                                  </div> */}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="pan-grp" id="accordion" role="tablist" aria-multiselectable="true">
                            { props?.autoTags?.map( item => (
                                <div className={`${styles.pnew}`}>
                                  <div className={`${styles.pborderbb}`} role="tab" id="headingTwo">
                                    <div onClick={ () => searchSecondaryTag(item.entity) } className={`${styles.ptitlefsl}`}>
                                      <a className={`${styles.ptitle} collapsed `}>
                                        {item.entity} <div className={`${styles.circlediv}`}><div className={`${styles.circlespan}`}>{item.count}</div></div>
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              ))
                            }

                            {/* <div className={`${styles.pnew}`}>
                              <div className={`${styles.pborderbb}`} role="tab" id="headingTwo">
                                <div className={`${styles.ptitlefsl}`}>
                                  <a className={`${styles.ptitle} collapsed `}>
                                    Python <div className={`${styles.circlediv}`}><div className={`${styles.circlespan}`}>9</div></div>
                                  </a>
                                </div>
                              </div>
                            </div>
                            <div className={`${styles.pnew}`}>
                              <div className={`${styles.pborderbb}`} role="tab" id="headingTwo">
                                <div className={`${styles.ptitlefsl}`}>
                                  <a className={`${styles.ptitle} collapsed `}>
                                    NodeJs <div className={`${styles.circlediv}`}><div className={`${styles.circlespan}`}>9</div></div>
                                  </a>
                                </div>
                              </div>
                            </div> */}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-9">
                      
                      <div style={{opacity: (searchedItem || secondarySearchedItem) ? 1 : 0}} className={`${styles.class_top}`}>
                      { searchedItem && <>
                          <div className={`${styles.desc_div}`}>{searchedItem}<span className={`${styles.newAlert}`}>{maxCount}</span></div>
                          <i onClick={()=>iterate("down")} className={`${styles.upleft} bi bi-caret-up-fill`} style={{ color: currentPosition == 0 ? undefined :'red' }} aria-hidden="true" ></i><i onClick={()=>iterate("up")} style={{ color: maxCount == currentPosition+1 ? undefined :'red' }} className={`${styles.downleft} bi bi-caret-down-fill`} aria-hidden="true" ></i>                        
                          <span className={`${styles.numdiv}`}>{maxCount ? currentPosition + 1 : 0}/{maxCount}</span>
                        </>
                      }

                        { secondarySearchedItem && <span className="ms-4">
                              <div className={`${styles.secondaryDesc_div}`}>{secondarySearchedItem}<span className={`${styles.newAlert}`}>{secondaryMaxCount}</span></div>
                              <i onClick={()=>secondaryIterate("down")} className={`${styles.upleft} bi bi-caret-up-fill`} style={{ color: secondaryCurrentPosition == 0 ? undefined :'red' }} aria-hidden="true" ></i><i onClick={()=>secondaryIterate("up")} style={{ color: secondaryMaxCount == secondaryCurrentPosition+1 ? undefined :'red' }} className={`${styles.downleft} bi bi-caret-down-fill`} aria-hidden="true" ></i>                        
                              <span className={`${styles.numdiv}`}>{secondaryMaxCount ? secondaryCurrentPosition + 1 : 0}/{secondaryMaxCount}</span>
                            </span>
                        }
                      </div>
                      
                      <div className={`${styles.class_parent} row me-3`}>
                        <div className={`${styles.class_child} col-md-12`}>
                          {/* <p>API takes 5th place in the top 10 programming languages of the future rank. This is one of the classic programming languages. Even our moms heard something about writing code using Java, although they do not even understand how it works. Java is a cost-efficient programming language that helps to shorten development time. Additionally, it improves the services of the application and drives innovation. It makes this programming language one of the best to learn and implement for software development. API project initiated 20 years ago, in 1991, by James Gosling, Mike Sheridan, and Patrick Naughton. Currently,API has 4 editions:</p>
                          <p> API Card for smart cards.</p>
                          <p> Micro Edition used for companies with limited resources</p>
                          <p> Stclass="col-md-9"andard Edition used for workstation environments </p>
                          <p>Enterprise Edition API EE) used for large distributed enterprise or Internet environments.
                          </p>

                          <p> Currently, such companies as Airbnb, Netflix, Google, Pinterest, Instagram, Spotify, Amazon use Java in their development processes. </p>
                          <p>This is surely one of the most demanded programming languages, and it seems that it will be in demand for many years. Java features: It has a huge number of open-source libraries Its community is countless It has a high level of security Ruby Number 13 in Statista best new programming languages rating, but perhaps number 1 in developing web applications. Like Swift and Kotlin, Ruby is used for certain clear goals; therefore, the number of developers who chose it is lower than multi-purpose JavaScript and Python. Ruby is focused on being simple and productive. It’s dynamic and open-source – many developers appreciate these two features. The distinctive feature of Ruby is its elegant syntax. Ruby is a ‘Japanese’ programming language developed in the mid 90’ies. Airbnb, Groupon, Github, Couchsurfing, Shopify, Ask.fm, Dribbble, Twitter use Ruby in their online presence. Ruby features: It allows simple and fast development of web applications It provides an elegant code It allows the developers to maintain reference counts in extension libraries.</p> */}
                          {console.log('itemitem', textData?.split('\n'))}
                          {textData?.split('\n').map((item) => <p dangerouslySetInnerHTML={{ __html: item }}></p> )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default SearchModal;

SearchModal.defaultProps = {
  itemToSearch: "",
  title: "",
  content: tData
}