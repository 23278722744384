import React from 'react';
import { Col, Button, Container, Row } from "react-bootstrap";
import styles from "../allcoelist.module.scss";
import like from "../../../assets/images/thumb.svg";
import liked from "../../../assets/images/thumb-fill.svg";
import comment from "../../../assets/images/comment.svg";
import fav from "../../../assets/images/fav-plain.svg";
import { apis } from '../../../services/Api';
import PopoverInfo from '../../PopoverInfo/PopoverInfo';




function LikeAndCmd(props) {
    let [isLiked, setIsLiked] = React.useState(props.data.like_count);
    let [likeCount, setLikeCount] = React.useState(props.data.is_liked);
    let [cid] = React.useState(props.cid);
    let [iid] = React.useState(props.data.id);

    const likeIdea = async () => {
        console.log("like idea");
        let result = await apis.like(cid, iid, 1);
        setIsLiked(1);
        setLikeCount(result.count);
    }

    const unLikeIdea = async () => {
        console.log("unlike idea");
        let result = await apis.like(cid, iid, 0);
        setIsLiked(0);
        setLikeCount(result.count);
    }

    return (
        <React.Fragment>

            <Col>
                {isLiked == 1 ?
                    <Button data-test="IdeaCardLike-unLikeIdea" variant="link" className="p-0" onClick={unLikeIdea}>
                        <img
                            alt="Like"
                            src={liked}
                            className={`${styles.like_img}`}
                        />
                        <div>{likeCount}</div>
                    </Button> :
                    <Button data-test="IdeaCardLike-likeIdea" variant="link" className="p-0" onClick={likeIdea}>
                        <img
                            alt="Like"
                            src={like}
                            className={`${styles.like_img}`}
                        />
                        <div>{likeCount}</div>
                    </Button>
                }
            </Col>
            <Col>
                <Button variant="link" className="p-0">
                    <img
                        alt="Comments"
                        src={comment}
                        className={`${styles.comment_img}`}
                    />
                    <div>{props.data.comment_count} ({props.data.client_comment_count})</div>
                </Button>
            </Col>
            {/* <Col>
                <PopoverInfo />
            </Col> */}
        </React.Fragment>
    );
}

export default LikeAndCmd;