import React from 'react';
import { Button, Col } from "react-bootstrap";
import favfill from "../../assets/images/fav.svg";
import fav from "../../assets/images/fav-plain.svg";
import stylesOne from "../../component/AllCoEGrid/allcoegrid.module.scss";
import stylesTwo from "../../component/AllCoEList/allcoelist.module.scss";
import { apis } from '../../services/Api';


function Favorite(props) {
    const [isFavorite, setFavorite] = React.useState(props.data.isFavorite);
    const [cid] = React.useState(props.data.cid);
    const [iid] = React.useState(props.data.iid);

    const favoriteIdea = async () => {
        // console.log("like idea");
        let result = await apis.favorite(cid, iid, 1);
        setFavorite(1);
    }

    const unFavoriteIdea = async () => {
        // console.log("unlike idea");
        let result = await apis.favorite(cid, iid, 0);
        setFavorite(0);
    }

    return (
        <React.Fragment>
            {props.data.isGridView ?
                <Button
                    data-test="Favorite-button"
                    variant="link"
                    className="d-flex pt-0 align-items-center pb-0 all-list"
                >
                    {isFavorite === 1 ?
                        <img
                            onClick={unFavoriteIdea}
                            alt="Favorite"
                            src={favfill}
                            className={`${stylesOne.fav_img}`}
                        />
                        :
                        <img
                            onClick={favoriteIdea}
                            alt="Favorite"
                            src={fav}
                            className={`${stylesOne.fav_img}`}
                        />
                    }
                    <span className="label p-2 pb-0 pt-1">My Favorite</span>
                </Button>
                :
                <Col>
                    <Button data-test="Favorite-button" variant="link" className="p-0 all-list">
                        {isFavorite === 1 ?

                            <img
                                onClick={() => unFavoriteIdea()}
                                alt="Favorite"
                                src={favfill}
                                className={`${stylesTwo.fav_img}`}
                            />
                            :
                            <img
                                onClick={() => favoriteIdea()}
                                alt="Favorite"
                                src={fav}
                                className={`${stylesTwo.fav_img}`}
                            />
                        }
                        <div></div>
                    </Button>
                </Col>
            }
        </React.Fragment>
    );
}

export default Favorite;