import React from 'react';
import convertToUnEscape from '../ConvertToUnEscape/ConvertToUnEscape';

function IdeaCardTag(props) {

    return (
        <React.Fragment>
            {props.data?.map((e, i) =>
                <span key={i} className="badge bg-info text-dark selected">{convertToUnEscape(e.name)}</span>
            )}
        </React.Fragment>
    );
}

export default IdeaCardTag;