import React from "react";
import { useParams } from 'react-router-dom';
import styles from "../AllCoEGrid/allcoegrid.module.scss";
import { Card, Row, Col, Container } from "react-bootstrap";
import Favorite from '../../common/Favorite/Favorite';
import comment from "../../assets/images/comment.svg";
import IdeaCardLob from "../../common/Lob/Lob";
import IdeaCardArtifactList from './ArtifactList/ArtifactList';
import IdeaCardTag from '../../common/Tag/Tag';
import timeDifference from '../../common/Time/Time'
import IdeaCardLike from './Like/Like';


const AllCoEGrid = (props) => {
  //for retrieve params
  const params = useParams();

  return (
    <>
      <Row xs={1} md={1} lg={2} className="g-4">
        {props.ideaList.map((e, i) =>
          <Col key={i}>
            <Card className={`mb-3 ${styles.card_white}`} >
              <Card.Body className="d-flex cursor-pointer" onClick={() => props.goToIdeaDetails(e.id)}>
                <Container fluid className="px-2">
                  <Row>
                    <Col md={7} className={`d-flex ${styles.card_title}`}>
                      <span
                        className="text-truncate cursor-pointer"
                        data-test="AllCoeGrid-component-title"
                      >
                        {props.convertToUnEscape(e.title)}
                      </span>
                    </Col>
                    <Col md={5} className="d-flex justify-content-end">
                      <Favorite data={{
                        isGridView: true,
                        isFavorite: e.is_favorite,
                        cid: params.cid,
                        iid: e.id
                      }} />
                    </Col>
                    <Col>
                      <IdeaCardLob lobs={e.lob?.split(",").map((e) => { return { 'name': e } })} />
                    </Col>
                    <Col md={12}>
                      <div className="description d-flex mt-2">
                        <span className="text-truncate-multiline">
                          {props.convertToUnEscape(e.description)}
                        </span>
                      </div>
                    </Col>
                    <Col md="12" className="d-flex">
                      <Col md={6} className="pt-2">
                        <Container className="px-0">
                          <IdeaCardArtifactList data={e} />
                          <Row className="mt-3">
                            <Col>
                              <IdeaCardTag data={e.tags?.split(",").map((e) => { return { 'name': e } })} />
                            </Col>
                          </Row>
                        </Container>
                      </Col>

                      <Col md={6} className="pt-3 profile-info">
                        <Col md={12} className="d-flex justify-content-end">
                          <div className="text-center">
                            {" "}
                            <img
                              src={e.display_image}
                              width="40"
                              className="rounded-circle"
                            />{" "}
                          </div>
                          <div className="text-start ps-3">
                            <h5 className="mt-2 mb-0 d-flex">
                              <span className="text-truncate">
                                {e.display_name}
                              </span>
                            </h5>{" "}
                            <span className="subtitle">{e.category_title}</span>
                            <Col md={12} className="d-flex">
                              <div className="tag-gray mt-1">{timeDifference(e.updated_at)}</div>
                            </Col>
                          </div>
                        </Col>
                      </Col>
                    </Col>
                  </Row>
                </Container>
              </Card.Body>
              <Card.Footer className={`${styles.c_footer}`}>
                <Container fluid className="px-0">
                  <Row>
                    <IdeaCardLike data={{ like_count: e.like_count, is_liked: e.is_liked, cid: params.cid, iid: e.id }} />
                    <Col md="auto">
                      <div className="d-flex pt-0 viewonly">
                        <img
                          alt="Comments"
                          src={comment}
                          className={`${styles.comment_img}`}
                        />
                        <span className="label p-2">
                          <b className="pe-1">{e.comment_count}</b>Comments (<b>{e.client_comment_count}</b> Client
                          feedback)
                        </span>
                      </div>
                    </Col>
                  </Row>
                </Container>
              </Card.Footer>
            </Card>
          </Col>
        )}
      </Row>
    </>
  );
};

export default AllCoEGrid;
