export const addCoeList = (data) => {
    return {
        type: "ADD_COE_LIST",
        coeList: data
    }
}

export const addAllCoEsCount = (data) => {
    return {
        type: "ADD_ALL_COE_COUNT",
        count: data
    }
}

export const addSearchText = (data) => {
    return {
        type: "ADD_SEARCH_TEXT",
        text: data
    }
}

//for adding user id from Header.js/component 
//userId is going to use for displaying Edit button at comment 
export const addUserId = (id) => {
    return {
        type: "ADD_USER_ID",
        id
    }
}

